// import { faCheck, faKey } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function PageHeaderLogs({
    as: _Component = _Builtin.Block,
    pageHeaderTitle = 'Logs Analysis',
    slotPageHeaderLargeButton,
    slotGenerationProcessing,
    rpButton = {},
    buttonLink = {
        href: '#',
    },
    rpBestButton = {},
    rpTeamsDropdown = {},
    showDropdown,
    rpDownloadTemplateLogs = {},
}) {


    return (
        <_Component className="header" tag="div">
            <_Builtin.Block
                className="background-color-blue200 padding-small"
                tag="div"
            >
                <_Builtin.Block className="header-container" tag="div">
                    <_Builtin.Block className="header-left" tag="div">
                        <_Builtin.Heading tag="h1">
                            {pageHeaderTitle}
                        </_Builtin.Heading>

                        <_Builtin.Link
                            className="button"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rpBestButton}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="" />
                                <ButtonText buttonText="Best Practice" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                        <_Builtin.Block
                        className="button-container flex-center relative margin-bottom margin-tiny"
                        tag="div"
                        style={{ position: 'relative' }}
                    >
                        <_Builtin.Block
                            className="button is-icon-gamedesign cursor-pointer"
                            tag="div"
                        >
                            <_Builtin.HtmlEmbed className="icon_ximm" value="&#xe908;" />
                            <_Builtin.Block {...rpDownloadTemplateLogs} className="margin-right text-size-tiny" tag="div">
                                {'Logs Template'}
                            </_Builtin.Block>
                        </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block className="header-right" tag="div">
        
                        {slotPageHeaderLargeButton ?? (
                            <>
                                {showDropdown && (
                                    <_Builtin.Block
                                        className="slotpageheadersmallbutton"
                                        tag="div"
                                    >
                                        <_Builtin.FormSelect
                                            className="input background-blue-150 min-h-3-4rem"
                                            name="Teams"
                                            data-name="Teams"
                                            required={false}
                                            multiple={false}
                                            id="Teams-1"
                                            {...rpTeamsDropdown}
                                        />
                                        <_Builtin.Block tag="div">
                                            {slotGenerationProcessing ?? (
                                                <LoaderGenerating />
                                            )}
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                )}
                            </>
                        )}

                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
