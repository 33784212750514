import { gamehelperModel } from 'entities/gamehelper';
import { createContext, useState, useContext } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'


const initialGamehelperState: gamehelperModel.State = {
    file: new File([''], ''),
    file_name: '',
    question: '',
    chunk_max_tokens: 0,
    top_k: 0,
    llama_road_params: {
        n_gpu_layers: 0,
        n_ctx: 0,
        n_batch: 0,
        use_mmap: false,
        main_gpu: 0,
        verbose: false,
    },
    llama_generation_params: {
        max_tokens: 0,
    },
    gamehelperLines: [],
    gamehelperUploadsPDF: [],
}

const gamehelperStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'GamehelperPage GamehelperStore',
}



export const gamehelperStore = createStore<gamehelperModel.GamehelperState>()(
    devtools(
        gamehelperModel.createGamehelperSlice(initialGamehelperState),
        gamehelperStoreDevtoolsOptions
    )
)

type GamehelperContextType = {
    gamehelperStore: StoreApi<gamehelperModel.State>
    selectedVideoIndex: number
    setSelectedVideoIndex?: (value: number) => void
    isModalRestartOpen: boolean
    setIsModalRestartOpen?: (value: boolean) => void
    isModalRegenerateOpen: boolean
    setIsModalRegenerateOpen?: (value: boolean) => void
    isModalExportOpen?: boolean
    setIsModalExportOpen?: (value: boolean) => void
    isRegenerateTriggered: boolean
    setIsRegenerateTriggered?: (value: boolean) => void
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isBestPracticeTriggered?: boolean
    setIsBestPracticeTriggered?: (value: boolean) => void
}

export const GamehelperContext = createContext<GamehelperContextType>({
    gamehelperStore,
    selectedVideoIndex: 0,
    isModalRestartOpen: false,
    isModalRegenerateOpen: false,
    isRegenerateTriggered: false,
    isModalBestPracticeOpen: false,
})


export const GamehelperProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalRestartOpen, setIsModalRestartOpen] = useState(false)
    const [isModalRegenerateOpen, setIsModalRegenerateOpen] = useState(false)
    const [isModalExportOpen, setIsModalExportOpen] = useState(false)
    const [isRegenerateTriggered, setIsRegenerateTriggered] = useState(false)
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isBestPracticeTriggered, setIsBestPracticeTriggered] = useState(false)
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

    return (
        <GamehelperContext.Provider
            value={{
                gamehelperStore,
                selectedVideoIndex: selectedVideoIndex,
                setSelectedVideoIndex: setSelectedVideoIndex,
                isModalRestartOpen: isModalRestartOpen,
                setIsModalRestartOpen: setIsModalRestartOpen,
                isModalRegenerateOpen: isModalRegenerateOpen,
                setIsModalRegenerateOpen: setIsModalRegenerateOpen,
                isModalExportOpen: isModalExportOpen,
                setIsModalExportOpen: setIsModalExportOpen,
                isRegenerateTriggered: isRegenerateTriggered,
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isBestPracticeTriggered: isBestPracticeTriggered,
                setIsBestPracticeTriggered: setIsBestPracticeTriggered,
            
            }}
        >
            {children}
        </GamehelperContext.Provider>
    )
}

export const useGamehelperContext = () => useContext(GamehelperContext);
