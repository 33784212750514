import {
    DialogGenerateOptionsButtons,
    DialogGenerateOptionsSlider,
    DialogGenerateOptionsUploadFile,
    DialogLine,
    DialogLineRegenerate,
    DialogLinesEmpty,
    DialogLinesList,
    DialogNpcSelectCard,
    DialogNpcSelectCardForm,
    SectionClosed,
    SectionDialogGenerate,
    SectionDialogNpcSelect,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { dialogModel } from 'entities/dialog'
import {
    useCreateDialogLineMutation,
    useCreatePromptMutation,
    useCreateStateChangesMutation,
    useDiagenFilesUploadMutation,
    useGetTagsMutation,
    useInitSessionMutation,
    useRegenerateDialogLineMutation,
    useUpdateHistoryMutation,
} from 'entities/dialog-line/dialog-line.queries'
import { DialogLine as DialogLineType } from 'entities/dialog-line/dialog-line.types'
import { StateTags, TagOption } from 'entities/session/session.model'

import { generateNextLine } from 'features/dialog-line/generate/generate-next-line'
import { regenerateOneLine } from 'features/dialog-line/generate/regenerate-line'
import { DialogContext } from 'pages/dialog/dialog-page.model'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Loader } from 'shared/ui/loader'
import { SliderRange } from 'shared/ui/slider'

type SectionDialogNpcSelectContainerProps = {
    isLoading?: boolean
    characters: characterTypes.Npcs
    // characters: characterTypes.Characters
    temperature: number
    toxicity: number
    token: number
    language: string
    isGenerating: boolean
    tags: StateTags
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => void
    setToken: (token: number) => void
    setLanguage: (language: string) => void
}
export function SectionDialogNpcSelectContainer({
    isLoading,
    characters,
    temperature,
    toxicity,
    token,
    language,
    isGenerating,
    tags,
    setToxicity,
    setTemperature,
    setToken,
    setLanguage,
}: SectionDialogNpcSelectContainerProps) {
    const { dialogStore } = useContext(DialogContext)
    const [fileNameTopics, setFileNameTopics] = useState<string>('');
    const [fileNameEvents, setFileNameEvents] = useState<string>('');
    const [fileNameCharactersInfos, setFileNameCharactersInfos] = useState<string>('');

    const [fileTopics, setFileTopics] = useState<File>();
    const [fileEvents, setFileEvents] = useState<File>();
    const [fileCharactersInfos, setFileCharactersInfos] = useState<File>();

    const [getUploadFiles, setGetUploadFiles] = useState(false);
    const [tagsOptions, setTagsOptions] = useState<TagOption[]>([]);
    const [otherCharacter, setOtherCharacter] = useState<string[]>(['', '']);
    let selectedCharacters = ['', ''];
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    });

    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false);

    const { setIsModalSwapOpen, isSwapTriggered, setIsSwapTriggered } =
        useContext(DialogContext)

    // load the dialog store when the component mounts
    useLayoutEffect(() => {
        dialogStore.getState().load()
    }, [])

    // save the dialog store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            dialogStore.getState().save()
        }
    }, [])

    // Subscribe to dialog store and listen for selected character changed
    useLayoutEffect(() => {
        const unsubscribeDialogStore = dialogStore.subscribe((state) => {
            // Only if character states changed 
            if (selectedCharacters[0] !== state.characterStates[0].character?.name || selectedCharacters[1] !== state.characterStates[1].character?.name) {
                if (state.characterStates[0].character?.name === 'Player') {
                    setOtherCharacter([
                        state.characterStates[1].character?.name || '',
                        state.characterStates[0].character?.name || '',
                ])} 
                else {
                    setOtherCharacter([
                        state.characterStates[0].character?.name || '',
                        state.characterStates[1].character?.name || '',
                    ])
                }
                
                if (state.characterStates[1].character?.name === 'Player') {
                    setOtherCharacter([
                        state.characterStates[0].character?.name || '',
                        state.characterStates[1].character?.name || '',
                    ])
                } 
                else {
                    setOtherCharacter([
                        state.characterStates[1].character?.name || '',
                        state.characterStates[0].character?.name || '',
                    ])
                }
            }
            selectedCharacters = [
                state.characterStates[0].character?.name || '',
                state.characterStates[1].character?.name || '',
            ]
        })

        // Unsubscribe when component gets destroyed
        return () => {
            unsubscribeDialogStore()
        }

    }, [])


    const handleFileUploadTopics = (uploadedFileTopics: File) => {
        const fileExtension = uploadedFileTopics.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileTopics(uploadedFileTopics);
            setFileNameTopics(uploadedFileTopics.name);
        }
    };

    const handleFileUploadEvents = (uploadedFileEvents: File) => {
        const fileExtension = uploadedFileEvents.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileEvents(uploadedFileEvents);
            setFileNameEvents(uploadedFileEvents.name);
        }
    };

    const handleFileUploadCharactersInfos = (uploadedFileCharactersInfos: File) => {
        const fileExtension = uploadedFileCharactersInfos.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileCharactersInfos(uploadedFileCharactersInfos);
            setFileNameCharactersInfos(uploadedFileCharactersInfos.name);
        }
    };

    useEffect(() => {
        if (tags) {
            const stateTagsOptions = tags.map((tag: string) => ({
                label: tag,
                value: tag,
            }));
            setTagsOptions(stateTagsOptions);
        }
    }, [tags]);

    const languageOptions = [
        {
            t: 'English',
            v: 'en',
        },
        {
            t: 'French',
            v: 'fr',
        },
        {
            t: 'Spanish',
            v: 'es',
        },
        {
            t: 'German',
            v: 'de',
        },
        {
            t: 'Italian',
            v: 'it',
        },
        {
            t: 'Portuguese',
            v: 'pt',
        },
    ]
    //init session
    const { mutate: initSession,
        isPending: isInitSessionPending,
        isSuccess: isInitSessionSuccess,
        isError: isInitSessionError,
    } = useInitSessionMutation()


    //upload files
    const { mutate: uploadDiagenFiles,
        isPending: isUploadDiagenFilesPending,
        isError: isUploadDiagenFilesError,
        isSuccess: isUploadDiagenFilesSuccess,
    } = useDiagenFilesUploadMutation()

    const {
        mutate: getTags,
        isPending: isGetTagsPending,
        isSuccess: isGetTagsSuccess,
        isError: isGetTagsError,
    } = useGetTagsMutation();


    // //DiagenEvent
    // const { mutate: callDiagenEvent,
    //     isPending: isDiagenEventPending,
    //     isSuccess: isDiagenEventSuccess,
    //     isError: isDiagenEventError,
    // } = useCreateDiagenEventMutation()

    // //Topic Detection

    // const { mutate: callTopicDetection,
    //     isPending: isTopicDetectionPending,
    //     isSuccess: isTopicDetectionSuccess,
    //     isError: isTopicDetectionError,
    // } = useCreateTopicDetectionMutation()

    //Create Prompt

    // const { mutate: handleCreatePrompt,
    //     isPending: isCreatePromptPending,
    //     isSuccess: isCreatePromptSuccess,
    //     isError: isCreatePromptError,
    // } = useCreatePromptMutation()


    // const handleTopicDetection = () => {
    //     callTopicDetection({
    //         topicDetection: {
    //             question: "Can I offer you food?",
    //             npc_name: "Abrogail",
    //             model_path: "./models/base/Meta-Llama-3.1-8B-Instruct-Q4_K_M.gguf",
    //             llama_generation_params: {
    //                 temperature: 0.1,
    //                 max_tokens: 20,
    //                 top_p: 0.9,
    //             },
    //             llama_load_params: {
    //                 n_gpu_layers: 12,
    //                 n_ctx: 512,
    //                 main_gpu: 1,
    //             },
    //         },
    //         onChunkReceived: (chunk: string) => {
    //             console.log('Received chunk:', chunk);
    //         },
    //     })
    //     }

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        });
        setIsModalPopupOpen(true);
    };

    useEffect(() => {
        if (isInitSessionSuccess) {
            openPopup('\ue91e', 'Session initialized', 'The session has been initialized successfully.');
        }
        if (isInitSessionError) {
            openPopup('\ue91e', 'Session not initialized', 'The session has not been initialized. Please try again.');
        }
    }, [isInitSessionSuccess, isInitSessionError]);

    useEffect(() => {
        if (isUploadDiagenFilesSuccess) {
            getTags();
        }
        if (isUploadDiagenFilesError) {
            openPopup('\ue91e', 'Files not uploaded', 'The files have not been uploaded. Please try again.');
        }
    }, [isUploadDiagenFilesSuccess, isUploadDiagenFilesError]);



    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }


    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }

    if (isLoading) {
        return (
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    background: '#1E2338',
                                    height: '327px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Loader size="parent" />
                            </div>
                        ))}
                    </>
                }
            />
        )
    }
    return (
        <>
            {/* <button className='button is-secondary' onClick={() => { callDiagenEvent({ diagenEvents: { npc_name: 'Abrogail', diagenEvent: "player_join_ranks" } }) }}>Call Diagen Events</button> */}
            {/* <button className='button is-secondary' onClick={() => { handleTopicDetection() }}>Call Topic Detection</button>        */}
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {/* add a button that logs the dialogStore */}
                        {characters.length > 0 && (
                            <>
                                {dialogStore
                                    .getState()
                                    .characterStates.map((_, index) => (
                                        <DialogNpcSelectCardContainer
                                            key={index}
                                            index={index}
                                            swap={isSwapTriggered}
                                            characters={characters}
                                            tagsOptions={tagsOptions}
                                            otherCharacter={otherCharacter}
                                            setOtherCharacter={setOtherCharacter}
                                            setSwap={setIsSwapTriggered}

                                            setSwapIsOpen={setIsModalSwapOpen}
                                            onSelectCharacter={(character) => {
                                                initSession();
                                                dialogStore
                                                    .getState()
                                                    .setCharacter(
                                                        index,
                                                        character
                                                    )
                                                dialogStore.setState({
                                                    dialogLines: [],
                                                })
                                            }}
                                        />
                                    ))}
                            </>
                        )}
                    </>
                }
                rpButtonSwap={{
                    onClick: (e: Event) => {
                        e.preventDefault()

                        if (!isGenerating) {
                            // swap the characters
                            const tempCharacterState = dialogStore.getState().characterStates[0];

                            dialogStore.getState().setCharacter(
                                0,
                                dialogStore.getState().characterStates[1].character
                            );
                            dialogStore.getState().setCharacter(1, tempCharacterState.character);

                            // Swap character backgrounds                            
                            const temp =
                                dialogStore.getState().characterStates[0]
                            dialogStore
                                .getState()
                                .setCharacterBackground(
                                    dialogStore.getState().characterStates[1].background,
                                    0,
                                )
                            dialogStore
                                .getState()
                                .setCharacterBackground(temp.background, 1)

                            //Modal open
                            setIsModalSwapOpen?.(true)
                        }
                    },
                    className: `button is-secondary ${isGenerating && 'is-disabled'}`,
                }}
            />
            <DialogGenerateOptionsSlider
                slotTemperatureRange={
                    <SliderRange
                    value={
                            transformValue(temperature, 0, 2, 0, 100)
                        } 
                    onChange={(value: number) => {
                        setTemperature(Math.round(
                            revertTransform(value, 0, 2, 0, 100)
                        ));
                    }}
                    min={0}
                    max={200}
                    />
                }
                slotTokenRange={
                    <SliderRange
                        value={Math.round(
                            transformValue(token, 10, 200, 0, 100)
                        )}
                        onChange={(value: number) => {
                            setToken(
                                Math.round(
                                    revertTransform(value, 10, 200, 0, 100)
                                )
                            )
                        }}
                    />
                }
                slotToxicityRange={
                    <SliderRange
                        value={toxicity * 100}
                        onChange={(value: number) => {
                            setToxicity(value / 100)
                        }}
                    />
                }
                rpLanguage={{
                    label: 'Language',
                    options: languageOptions.sort((a, b) => a.t.localeCompare(b.t)),
                    value: language,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedLanguage = e.target.value
                        setLanguage(selectedLanguage)
                    },
                }}
            />
            {getUploadFiles ? (
                <DialogGenerateOptionsUploadFile
                    fileNameEvents={fileNameEvents}
                    fileNameCharactersInfos={fileNameCharactersInfos}
                    fileNameTopics={fileNameTopics}
                    rpInitSession={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            initSession();
                        },
                        className: `button is-secondary ${isInitSessionPending && 'is-disabled '}`,
                    }}
                    rpUploadTopics={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.csv, .xls, .xlsx';
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement;
                                if (target.files) {
                                    handleFileUploadTopics(target.files[0]);
                                }
                            };
                            input.click();
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault();
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault();
                            const droppedFileTopics = e.dataTransfer.files[0];
                            handleFileUploadTopics(droppedFileTopics);
                        },
                    }}
                    rpUploadEvents={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.csv, .xls, .xlsx';
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement;
                                if (target.files) {
                                    handleFileUploadEvents(target.files[0]);
                                }
                            };
                            input.click();
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault();
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault();
                            const droppedFileEvents = e.dataTransfer.files[0];
                            handleFileUploadEvents(droppedFileEvents);
                        },
                    }}
                    rpUploadCharactersInformations={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.csv, .xls, .xlsx';
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement;
                                if (target.files) {
                                    handleFileUploadCharactersInfos(target.files[0]);
                                }
                            };
                            input.click();
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault();
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault();
                            const droppedFileCharactersInfos = e.dataTransfer.files[0];
                            handleFileUploadCharactersInfos(droppedFileCharactersInfos);
                        },
                    }}
                    rpButtonSendFiles={{
                        onClick: (e: React.MouseEvent) => {
                            if (fileTopics && fileEvents && fileCharactersInfos) {
                                uploadDiagenFiles({ diagenuploadPDF: { topics: fileTopics, diagenEvents: fileEvents, characterInformation: fileCharactersInfos } })
                            }
                        },
                    }}
                    onClose={{
                        onClick: () => {
                            setGetUploadFiles(false)
                        },
                    }}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setGetUploadFiles(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Additionnal options'}
                    subtitle={'Upload files to improve the quality of the generated dialog'}
                />
            )}
        </>
    )
}

type DialogNpcSelectCardContainerProps = {
    index: number
    swap: boolean | undefined
    characters: characterTypes.Npcs
    tagsOptions: TagOption[]
    otherCharacter: string[]
    setSwap: ((value: boolean) => void) | undefined
    setSwapIsOpen: ((value: boolean) => void) | undefined
    setOtherCharacter: (value: string[]) => void
    onSelectCharacter?: (character?: characterTypes.Npc) => void
}
function DialogNpcSelectCardContainer({
    index,
    swap,
    characters,
    tagsOptions,
    otherCharacter,
    setSwap,
    onSelectCharacter,
    setSwapIsOpen,
    setOtherCharacter,
}: DialogNpcSelectCardContainerProps) {


    const { playerStore, dialogStore } = useContext(DialogContext)
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        null
    )

    const [npcs, setNpcs] = useState<any[] | null | undefined>(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedCharacter, setSelectedCharacter] =
        useState<characterTypes.Npc | null>(null)

    const [selectedTagsOptions, setSelectedTagsOptions] = useState<TagOption[]>([]);
    const [customName, setCustomName] = useState<string | null>(null)
    const npc_name = selectedCharacter?.name || '';
    const other_name = otherCharacter || '';
    const [core_description, setBackground] = useState(
        dialogStore.getState().characterStates[index].background ||
        selectedCharacter?.background ||
        ''
    )



    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (
                state.currentTeam?.name !== selectedTeam &&
                npcs !== state.npcs
            ) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setNpcs(state.npcs)
                setSelectedCharacter(null)
                setCharacterImage(null)
                setBackground('')
                dialogStore.getState().reset()
            }
        })
    }, [selectedTeam, npcs])

    useEffect(() => {
        if (selectedCharacter) {
            const savedStates = JSON.parse(
                localStorage.getItem('states') || '{}'
            )
            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacter.name
            )
            setCharacterImage(characterImage?.image || null)
        }
    }, [selectedCharacter])

    useEffect(() => {
        if (swap) {
            const temp_character =
                characters.find(
                    (character) =>
                        character.id ===
                        dialogStore.getState().characterStates[index]?.character
                            ?.id
                ) || null
            setSelectedCharacter(temp_character)
            setBackground(temp_character?.background || '')
            setSwap?.(false)
        }
    }, [swap])

    const defaultValue = {
        t: 'Choose your NPC',
        v: '',
    }

    const characterOptions = [
        defaultValue,
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    //callStateChanges
    const { mutate: callStateChanges,
    } = useCreateStateChangesMutation()

    //Create Prompt
    const { mutate: handleCreatePrompt,
        isSuccess: isCreatePromptSuccess,
        data: datasPrompt } = useCreatePromptMutation();

    useEffect(() => {
        if (isCreatePromptSuccess) {
            const newPrompt = datasPrompt.prompt ||
                `Your name is ${npc_name} and you are talking to ${other_name}. You have to talk in the first perspective with dialogue.`;
            setBackground(newPrompt);
            dialogStore.getState().setCharacterBackground(newPrompt, index);
        }
    }, [isCreatePromptSuccess]);

    useEffect(() => {
        if (selectedCharacter?.name === 'Player') {
            setBackground(``);
            dialogStore.getState().setCharacterBackground(``, index);
        } else {
            setBackground(selectedCharacter?.background || '');
            dialogStore.getState().setCharacterBackground(selectedCharacter?.background || '', index);
        }
    }, [selectedCharacter, other_name, index, dialogStore])

    return (
        <>
            <DialogNpcSelectCard
                dialogNpcSelectCardImg={
                    selectedCharacter &&
                        selectedCharacter.name === 'Player'
                        ? playerStore.getState().gender === 'female'
                            ? '/images/you_female.png'
                            : '/images/you_male.png'
                        : characterImage || '/images/blank.png'
                    // : "/images/blank.png"
                }
                slotNpcCardForm={
                    <DialogNpcSelectCardForm

                        rpDialogNpcSelectCardDropdown={{
                            label: 'Choose your NPC',
                            value: selectedCharacter?.id.toString(),
                            name: selectedCharacter?.name.toString(),
                            options: characterOptions,
                            onChange: (event: any) => {
                                const character =
                                    characters.find(
                                        (character) =>
                                            character.id.toString() ===
                                            event.target.value
                                    ) || null
                                setSelectedCharacter(character)
                                setBackground(character?.background || '')
                                onSelectCharacter?.(character || undefined)
                                if (character?.name === 'Player') {
                                    playerStore
                                        ?.getState()
                                        .setName('Player')
                                    playerStore
                                        ?.getState()
                                        .setGender(
                                            Math.random() < 0.5
                                                ? 'male'
                                                : 'female'
                                        )
                                }
                            },
                        }}
                        isPlayerSelected={selectedCharacter?.name === 'Player'}
                        isTagsEmpty={tagsOptions.length === 0}
                        rpPlayerCustomName={{
                            className: "input background-blue-150 min-h-3rem cursor-pointer",
                            value: customName,
                            onChange: (event: any) => {
                                setCustomName(event.target.value)
                                if (index === 0) {
                                    setOtherCharacter([
                                        otherCharacter[0],
                                        event.target.value,
                                    ])
                                } else {
                                    setOtherCharacter([
                                        event.target.value,
                                        otherCharacter[1],
                                    ])
                                }
                            }
                        }}
                        rpDialogNpcSelectCardCoreDescription={{
                            className: "input background-blue-150 min-h-10rem overflow-scroll",
                            value: core_description,
                            disabled: selectedCharacter?.name === 'Player',
                            onChange: (event: any) => {
                                setBackground(event.target.value)
                                dialogStore.getState().setCharacterBackground(event.target.value, index)
                            },
                        }}
                        rpTagsDropdown={{
                            placeholder: 'Choose your tags...',
                            disabled: tagsOptions.length === 0 || selectedCharacter?.name === 'Player',
                            value: selectedTagsOptions,
                            options: tagsOptions,
                            onChange: (selectedOptions: TagOption[]) => {
                                setSelectedTagsOptions(selectedOptions);
                                callStateChanges({ stateChanges: { stateTags: selectedOptions.map((option) => option.value), npc_name: selectedCharacter?.name ?? '' } });
                                handleCreatePrompt({ promptData: { npc_name: selectedCharacter?.name ?? '', player_name: otherCharacter[index] ?? '', local_exec: true } });
                            },
                        }}
                        disabled={tagsOptions.length === 0 || selectedCharacter?.name === 'Player'}
                    />

                }
            />
        </>
    )
}


type SectionDialogGenerateContainerProps = {
    characters: characterTypes.Npcs
    temperature: number
    toxicity: number
    token: number
    language: string
    setIsGenerating: (isGenerating: boolean) => void
}

export function SectionDialogGenerateContainer({
    characters,
    temperature,
    toxicity,
    token,
    language,
    setIsGenerating,
}: SectionDialogGenerateContainerProps) {
    const {
        setIsModalRegenerateOpen,
        isRegenerateTriggered,
        setIsRegenerateTriggered,
    } = useContext(DialogContext)

    const { dialogStore } = useContext(DialogContext)

    const [isComplete, setIsComplete] = useState(false)
    const [lines, setLines] = useState<DialogLineType[]>(
        dialogStore.getState().dialogLines
    )
    // selected characters
    const [selectedCharacters, setSelectedCharacters] = useState(
        dialogStore.getState().characterStates
    )

    // line generation
    const [linesToGenerate, setLinesToGenerate] = useState(0)
    const [shouldScroll, setShouldScroll] = useState(true)

    // end regeneration
    const [startEndIndex, setStartEndIndex] = useState(-1)

    // line generation
    const {
        mutate: createDialogLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
    } = useCreateDialogLineMutation()


    // subscribe to state changes from dialogStore
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            setSelectedCharacters(state.characterStates)
        })
    }, [dialogStore])

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegeneratingEnd,
        isSuccess: isRegeneratedEnd,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    //Update History
    const { mutate: handleUpdateHistory,
        isPending: isUpdateHistoryPending,
        isSuccess: isUpdateHistorySuccess,
        isError: isUpdateHistoryError,
    } = useUpdateHistoryMutation()



    // subscribe to state changes
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            computeIsComplete(state)
            setLines(state.dialogLines)
        })
        computeIsComplete(dialogStore.getState())
    }, [dialogStore])


    // listen to line generation success
    useLayoutEffect(() => {
        if (isSuccessCreateLine) {
            setLinesToGenerate(linesToGenerate - 1)
            const lastLine = dialogStore.getState().dialogLines.slice(-1)[0];
            handleUpdateHistory({ updateHistories: { npc_name: lastLine.npc_name, question: lastLine.question, response: lastLine.response } });
            if (shouldScroll) {
                window.scrollTo(0, document.body.scrollHeight)
            }

            if (
                selectedCharacters.some(
                    (character) =>
                        character.character?.name === 'Player'
                )
            ) {
                dialogStore?.setState({
                    dialogLines: [
                        ...lines,
                        {
                            npc_name: 'Player',
                            core_description: '',
                            question: lines[lines.length - 1].response,
                            response: '',
                            llama_generation_params: {

                                max_tokens: 0,
                                temperature: 0,
                                stream: true,
                                top_p: 0,
                                top_k: 0,
                                stop: [],
                                frequency_penalty: 0,
                                presence_penalty: 0,


                            },
                            toxicity: 0,
                            language: language,
                        },
                    ],
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateLine])

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[startEndIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
            setStartEndIndex((index) => index + 1)
            const lastLine = dialogStore.getState().dialogLines.slice(-1)[0];
            handleUpdateHistory({ updateHistories: { npc_name: lastLine.npc_name, question: lastLine.question, response: lastLine.response } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // generate next line if needed
    useLayoutEffect(() => {
        if (linesToGenerate > 0) {
            generateNextLine(createDialogLine, dialogStore, {
                llama_generation_params: {
                    max_tokens: token,
                    temperature: temperature,
                    stream: true,
                    top_p: 0.95,
                    top_k: 0,
                    stop: ["\n"],
                    frequency_penalty: 0.8,
                    presence_penalty: 0.3,
                },
                toxicity: toxicity,
                language: language,
            }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linesToGenerate])

    // compute is the form is complete
    function computeIsComplete(state: dialogModel.State) {
        if (
            state.characterStates.every((characterState) => {
                return characterState.character !== undefined
            })
        ) {
            setIsComplete(true)
        } else {
            setIsComplete(false)
        }
    }

    // ask to regenerate all the dialog
    useLayoutEffect(() => {
        if (isRegenerateTriggered) {
            dialogStore.setState({
                dialogLines: [],
            })
            if (selectedCharacters[0].character?.name === 'Player') {
                dialogStore?.setState({
                    dialogLines: [
                        {
                            npc_name: 'Player',
                            core_description: '',
                            question:
                                lines.length > 0
                                    ? lines[lines.length - 1].response
                                    : '',
                            response: '',
                            llama_generation_params: {
                                max_tokens: 0,
                                temperature: 1,
                                stream: true,
                                top_p: 0.95,
                                top_k: 0,
                                stop: ["\n"],
                                frequency_penalty: 0.8,
                                presence_penalty: 0.3,
                            },
                            toxicity: 0,
                            language: language,
                        },
                    ],
                })
                setIsRegenerateTriggered?.(false)
            } else if (
                selectedCharacters[1].character?.name === 'Player'
            ) {
                generateLines(1, dialogStore.getState().startEndIndex)
                setIsRegenerateTriggered?.(false)
            } else {
                generateLines(
                    lines.length,
                    dialogStore.getState().startEndIndex
                )
                setIsRegenerateTriggered?.(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegenerateTriggered])

    // ask to generate some lines
    function generateLines(numLines: number, startEndIndex?: number) {
        setShouldScroll(true)
        if (
            selectedCharacters[lines.length % 2].character?.name ===
            'Player'
        ) {
            dialogStore?.setState({
                dialogLines: [
                    ...lines,
                    {
                        npc_name: 'Player',
                        core_description: '',
                        question:
                            lines.length > 0
                                ? lines[lines.length - 1].response
                                : '',
                        response: '',
                        llama_generation_params: {
                            max_tokens: 0,
                            temperature: 0,
                            stream: true,
                            top_p: 0.95,
                            top_k: 0,
                            stop: ["\n"],
                            frequency_penalty: 0.8,
                            presence_penalty: 0.3,
                        },
                        toxicity: 0,
                        language: language,
                    },
                ],
            })
        } else {
            setLinesToGenerate(numLines)
            dialogStore.setState({
                startEndIndex:
                    startEndIndex !== undefined ? startEndIndex : numLines / 2,
            })
        }
    }

    // ask to regenerate the end of the conversation
    function regenerateEnd(index: number) {
        setStartEndIndex(index)
        const lastLine = dialogStore.getState().dialogLines.slice(-1)[0];
        handleUpdateHistory({ updateHistories: { npc_name: lastLine.npc_name, question: lastLine.question, response: lastLine.response } });
    }

    useLayoutEffect(() => {
        if (!isGeneratingLine && linesToGenerate <= 1) {
            setIsGenerating(false)
        } else {
            setIsGenerating(true)
        }
    }, [isGeneratingLine])


    return (
        <>
            <SectionDialogGenerate
                slotDialogGenerate={
                    <>
                        {lines.length === 0 && (
                            <>
                                <DialogGenerateOptionsButtonsContainer
                                    isEnabled={isComplete && !isGeneratingLine}
                                    onClick={generateLines}
                                />
                                {isGeneratingLine ? (
                                    <div
                                        style={{
                                            background: '#1E2338',
                                            height: '150px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Loader size="parent" />
                                    </div>
                                ) : (
                                    <DialogLinesEmpty />
                                )}
                            </>
                        )}
                        {lines.length !== 0 && (
                            <DialogLinesList
                                slotDialogueLines={
                                    <>
                                        {lines
                                            .filter(
                                                (_, index) =>
                                                    startEndIndex < 0 ||
                                                    startEndIndex > index
                                            )
                                            .map((_, index) => (
                                                <DialogLineContainer
                                                    key={index}
                                                    index={index}
                                                    isPending={isGeneratingLine}
                                                    generateEnd={regenerateEnd}
                                                    language=""
                                                    toxicity={
                                                        lines[index].toxicity
                                                    }
                                                    setToxicity={(
                                                        toxicity: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].toxicity = toxicity
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return toxicity
                                                    }}
                                                    temperature={
                                                        lines[index].llama_generation_params.temperature
                                                    }
                                                    setTemperature={(
                                                        temperature: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].llama_generation_params.temperature =
                                                            temperature
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return temperature
                                                    }}
                                                />
                                            ))}
                                        {(isGeneratingLine ||
                                            isRegeneratingEnd) && (
                                                <div
                                                    style={{
                                                        background: '#1E2338',
                                                        height: '150px',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    <Loader size="parent" />
                                                </div>
                                            )}
                                    </>
                                }
                                rpButtonGenerateResponse={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            generateLines(
                                                1,
                                                dialogStore.getState()
                                                    .startEndIndex
                                            )
                                    },
                                    className: `button ${isGeneratingLine && 'is-disabled'}`,
                                }}
                                rpButtonRegenerateAll={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            setIsModalRegenerateOpen?.(true)
                                    },
                                    className: `button is-secondary ${isGeneratingLine && 'is-disabled'}`,
                                }}
                            />
                        )}
                    </>
                }
            />
        </>
    )
}

type DialogLineContainerProps = {
    index: number
    isPending: boolean
    generateEnd: (index: number) => void
    language: string
    temperature: any
    toxicity: any
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => number
}
function DialogLineContainer({
    index,
    isPending,
    generateEnd,
}: DialogLineContainerProps) {
    const { dialogStore, playerStore } = useContext(DialogContext)
    const lines = dialogStore.getState().dialogLines
    const line = lines[index]
    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number, // 50
        min: number, // 10
        max: number, // 300
        range_min: number, // 0
        range_max: number // 100
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }

    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }
    const [player, setPlayer] = useState(playerStore?.getState())

    // start end radio button condition
    const [isStartEndSelected, setIsStartEndSelected] = useState(
        dialogStore.getState().startEndIndex === index
    )

    // listen to start end radio change
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            setIsStartEndSelected(state.startEndIndex === index)
        })
    }, [])

    // listen to start end radio change
    useLayoutEffect(() => {
        playerStore?.subscribe((state) => {
            setPlayer(state)
        })
    }, [])

    // line regeneration
    const [regeneratingLineIndex, setRegeneratingLineIndex] = useState(-1)

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegenerating,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    const {
        mutate: handleUpdateHistory,
        isPending: isUpdateHistoryPending,
        isSuccess: isUpdateHistorySuccess,
        isError: isUpdateHistoryError,
    } = useUpdateHistoryMutation()

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[regeneratingLineIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // ask to regenerate a line
    function regenerateLine(
        lineIndex: number,
        llama_generation_params: {
            max_tokens: number
            temperature: number
            stream: boolean
            top_p: number
            top_k: number
            stop: string[]
            frequency_penalty: number
            presence_penalty: number
        },
        toxicity: number,
        language: string
    ) {
        setRegeneratingLineIndex(lineIndex)
        regenerateOneLine(regenerateDialogLine, dialogStore, {
            lineIndex,
            llama_generation_params: {
                max_tokens: llama_generation_params.max_tokens,
                temperature: llama_generation_params.temperature,
                stream: llama_generation_params.stream,
                top_p: llama_generation_params.top_p,
                top_k: llama_generation_params.top_k,
                stop: llama_generation_params.stop,
                frequency_penalty: llama_generation_params.frequency_penalty,
                presence_penalty: llama_generation_params.presence_penalty,
            },
            toxicity: toxicity,
            language: language,
        })
    }

    return (
        <>
            <DialogLineRegenerate
                dialogLineRegenerateNumber={index + 1}
                rpRegenerateButton={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        generateEnd(index)
                    },
                    className: `button is-secondary ${isPending && 'is-disabled'}`,
                }}
            />
            {isRegenerating && regeneratingLineIndex === index && line.response.length === 0 ? (
                <div
                    style={{
                        background: '#1E2338',
                        height: '150px',
                        borderRadius: '8px',
                    }}
                >
                    <Loader size="parent" />
                </div>
            ) : (
                <DialogLine
                    dialogLineImage={
                        dialogStore.getState().characterStates[index % 2]
                            .character?.name === 'Player'
                            ? playerStore.getState().gender === 'female'
                                ? '/images/you_female.png'
                                : '/images/you_male.png'
                            : dialogStore.getState().characterStates[index % 2].character?.image || "/images/blank.png"
                    }
                    rpDialogLine={{
                        className: `dialogue-output-wrapper  ${index % 2 !== 0 && 'reverse-row'}`,
                    }}
                    slotDialogLinePlayerName={
                        dialogStore.getState().characterStates[index % 2]
                            .character?.name === 'Player'
                            ? ''
                            : null
                    }
                    rpDialogLineInput={{
                        value: line.response,
                        onChange: (e: Event) => {
                            e.preventDefault()
                            const line =
                                dialogStore.getState().dialogLines[index]
                            line.response = (e.target as any).value
                            dialogStore.setState({
                                dialogLines: [
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(0, index),
                                    line,
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(index + 1),
                                ],
                            })
                        },
                    }}
                    rpDialogOutputTop={{
                        className: `dialogue-output-top margin-bottom margin-xxsmall max-width-full ${index % 2 !== 0 && 'reverse-row'}`,
                    }}
                    slotTemperatureRange={
                        <SliderRange
                        max={200}
                        // value={line.llama_generation_params.temperature * 100}
                        value={Math.round(
                            transformValue(line.llama_generation_params.temperature, 0, 2, 0, 100)
                        )}
                        onChange={(value: number) => {
                            let newValue = value / 100
                            dialogStore.setState({
                                dialogLines: [
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(0, index),
                                    {
                                        ...line,
                                        llama_generation_params: {
                                            ...line.llama_generation_params,
                                            temperature: newValue
                                        }
                                    },
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(index + 1),
                                ],
                            })
                            line.llama_generation_params.temperature = newValue
                        }
                        }
                    />
                    }
                    slotTokenRange={
                        <SliderRange
                            value={Math.round(
                                transformValue(line.llama_generation_params.max_tokens, 10, 200, 0, 100)
                            )}
                            onChange={(value: number) => {
                                // Set the token in the store
                                dialogStore.setState({
                                    dialogLines: [
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(0, index),
                                        {
                                            ...line,
                                            llama_generation_params: {
                                                ...line.llama_generation_params,
                                                max_tokens: Math.round(
                                                    revertTransform(
                                                        value,
                                                        10,
                                                        200,
                                                        0,
                                                        100
                                                    )
                                                ),
                                            },
                                        },
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(index + 1),
                                    ],
                                })
                                // update the line
                                line.llama_generation_params.max_tokens = Math.round(
                                    revertTransform(value, 10, 200, 0, 100)
                                )
                            }}
                        />
                    }
                    slotToxicityRange={
                        <SliderRange
                            value={line.toxicity * 100}
                            onChange={(value: number) => {
                                let newValue = value / 100
                                dialogStore.setState({
                                    dialogLines: [
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(0, index),
                                        { ...line, toxicity: newValue },
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(index + 1),
                                    ],
                                })
                                line.toxicity = newValue
                            }}
                        />
                    }
                    rpDialogLineRegenerate={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            regenerateLine(
                                index,
                                line.llama_generation_params,
                                line.toxicity,
                                line.language
                            )
                            handleUpdateHistory({ updateHistories: { npc_name: line.npc_name, question: line.question, response: line.response } });
                        },
                    }}
                />
            )}
        </>
    )
}

type DialogGenerateOptionsButtonsContainerProps = {
    isEnabled?: boolean
    onClick: (numLines: number) => void
}
function DialogGenerateOptionsButtonsContainer({
    isEnabled = false,
    onClick,
}: DialogGenerateOptionsButtonsContainerProps) {
    const { dialogStore } = useContext(DialogContext)
    const [playerActive, setPlayerActive] = useState(false)
    const [selectedCharacters, setSelectedCharacters] = useState(
        dialogStore.getState().characterStates
    )

    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            // set selected characters
            setSelectedCharacters(state.characterStates)
        })
    }, [dialogStore])

    useLayoutEffect(() => {
        if (
            selectedCharacters.some(
                (character) => character.character?.name === 'Player'
            )
        ) {
            setPlayerActive(true)
        } else {
            setPlayerActive(false)
        }
    }, [selectedCharacters])

    return (
        <>
            <DialogGenerateOptionsButtons
                rpGenerateLeft={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(4)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateMiddle={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(6)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateRight={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(8)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateYouAsAPlayer={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(1)
                    },
                    className: `button is-xlarge is-secondary ${!playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
            />
        </>
    )
}
