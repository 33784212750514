import { SectionDataUsage } from 'devlink'
import { Keys } from 'entities/session/session.model'

type SectionDataUsageContainerProps = {
    selectedKeys: Keys
}


export function SectionDataUsageContainer({
        selectedKeys
    }: SectionDataUsageContainerProps) {

    return (
        <>
            <SectionDataUsage selectedKeys = {selectedKeys}/>
        </>
    )
}
