import { sessionModel } from 'entities/session'
import { dbBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { AddMemberSchema, CreateTeamSchema, DeleteTeamSchema, EditUserSchema, EditTeamSchema, EditUsageSchema } from './admin.contracts'


export async function createTeamMutation(params: {
    name: string
    valid_until: string
    valid_from: string
    allow_admin_creation: boolean
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.name) {
        body.append('name', params.name);
    }
    if (params.valid_from) {
        body.append('valid_from', params.valid_from);
    }
    if (params.valid_until) {
        body.append('valid_until', params.valid_until);
    }
    body.append('allow_admin_creation', "true");


    return createFormMutation({
        request: {
            url: dbBaseUrl(`/team/create`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(CreateTeamSchema),
        },
    })
}

export async function deleteTeamMutation(params: {
    name: string
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.name) {
        body.append('name', params.name);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/team`),
            method: 'DELETE',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(DeleteTeamSchema),
        },
    })
}

export async function addMemberToTeamMutation(params: {
    uid: string,
    team: string
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.uid) {
        body.append('uid', params.uid);
    }
    if (params.team) {
        body.append('team', params.team);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/team/add`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(AddMemberSchema),
        },
    })
}

export async function editUserMutation(params: {
    uid: string
    is_admin: boolean
    is_owner: boolean
    can_manage_models: boolean
    can_manage_npcs: boolean
    can_manage_datasets: boolean
    can_download_gepetto: boolean
    can_download_ariel: boolean
    can_download_diagen: boolean
    valid_until: string
    valid_from: string
    team: string
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.uid) {
        body.append('uid', params.uid);
    }
    body.append('is_owner', params.is_owner.toString());
    body.append('is_admin', params.is_admin.toString());
    body.append('can_manage_models', params.can_manage_models.toString());
    body.append('can_manage_npcs', params.can_manage_npcs.toString());
    body.append('can_manage_datasets', params.can_manage_datasets.toString());
    body.append('can_download_gepetto', params.can_download_gepetto.toString());
    body.append('can_download_ariel', params.can_download_ariel.toString());
    body.append('can_download_diagen', params.can_download_diagen.toString());
    body.append('valid_until', params.valid_until);
    body.append('valid_from', params.valid_from);
    body.append('team', params.team);

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/team/edit-user`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(EditUserSchema),
        },
    })
}

export async function modifyTeamMutation(params: {
    name: string
    valid_from: string
    valid_until: string
}) {
    

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.name) {
        body.append('name', params.name);
    }
    if (params.valid_from) {
        body.append('valid_from', params.valid_from);
    }
    if (params.valid_until) {
        body.append('valid_until', params.valid_until);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/keys`),                    
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(EditTeamSchema),
        },
    })
}

export async function updateUsageMutation(params: {
    team: string
    usage: string
    type: string
}) {
    

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.usage) {
        body.append('usage', params.usage);
    }
    if (params.type) {
        body.append('type', params.type);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/usage`), 
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(EditUsageSchema),
        },
    })
}