import * as _Builtin from '../_Builtin';
import { ButtonIcon } from '../_Component/ButtonIcon';
import { ButtonText } from '../_Component/ButtonText';

export function CreateTeam({
    as: _Component = _Builtin.Section,
    rdTeam,
    validFrom,
    validUntil,
    rdButtonCreate,
    onClose,
    rpIcon = "\ue902",
}) {


    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small"
                tag="div"
            >
                            <_Builtin.Block
                className="ds-my-universe-wrapper padding-small rounded-top"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall"
                    tag="div"
                >
          <_Builtin.Block
            className="icon_ximm _2 margin-bottom margin-xxsmall cursor-pointer"
            tag="div"
            {...onClose}
          >
            {rpIcon}
          </_Builtin.Block > 
                    <_Builtin.Heading tag="h2">
                        {'Create Team'}
                    </_Builtin.Heading>
                    </_Builtin.Block>

                </_Builtin.Block>
                <_Builtin.Block
                            className="batch-form section-data padding-xsmall rounded-bottom"
                            tag="div"
                        >
                    <_Builtin.FormBlockLabel
                        className="text-size-small margin-bottom margin-xxsmall"
                        htmlFor="field"
                    >
                        {'New Team: Name'}
                    </_Builtin.FormBlockLabel>
                    <_Builtin.Block
                        className="light-border margin-bottom margin-xsmall"
                        tag="div"
                    >
                        <_Builtin.FormTextInput
                            className="input background-blue-150 min-h-3-4rem"
                            required={false}
                            autoFocus={false}
                            placeholder="Give the new team a name"
                            maxLength={200}
                            name="field-2"
                            data-name="Field 2"
                            {...rdTeam}
                        />
                    </_Builtin.Block>
                    <_Builtin.FormBlockLabel
                        className="text-size-small margin-bottom margin-xsmall"
                        htmlFor="field"
                    >
                        {'New team: API Keys access rights'}
                    </_Builtin.FormBlockLabel>
                    <_Builtin.FormInlineLabel className='margin-left margin-xxsmall'>
                        {'Valid from: ' + validFrom.value}
                        <_Builtin.FormTextInput
                            type="date"
                            name="valid_from"
                            data-name="valid_from"
                            required={false}
                            id="valid_from"
                            form={{
                                type: 'valid_from-input',
                                name: 'valid_from',
                            }}
                            inputtype="custom"
                            className='background-blue-150 text-color-white'
                            {...validFrom}
                        />
                    </_Builtin.FormInlineLabel>
                    <_Builtin.FormInlineLabel className='margin-left margin-xlarge'>
                        {'Valid until: ' + validUntil.value}
                        <_Builtin.FormTextInput
                            type="date"
                            name="valid_until"
                            data-name="valid_until"
                            required={false}
                            id="valid_until"
                            form={{
                                type: 'valid_until-input',
                                name: 'valid_until',
                            }}
                            inputtype="custom"
                            className='background-blue-150 text-color-white'
                            {...validUntil}
                        />
                    </_Builtin.FormInlineLabel>
                    <_Builtin.Block className="button-group" tag="div">
                        <_Builtin.Link
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rdButtonCreate}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe914;" />
                                <ButtonText buttonText="Create Team" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
        </_Component>
    );
}