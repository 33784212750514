import { characterTypes } from 'entities/character'
import { DialogLines } from 'entities/dialog-line/dialog-line.types'
import { StateCreator } from 'zustand'

export type State = {
    characterStates: {
        character?: characterTypes.Npc
        background: string
    }[]
    dialogLines: DialogLines
    startEndIndex: number
}

export type Actions = {
    setCharacter: (index: number, character?: characterTypes.Npc) => void
    setCharacterBackground: (background: string, index?: number) => void
    setDialogLines: (state: DialogLines) => void
    setStartEndIndex: (index: number) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type DialogState = State & Actions
export const createDialogSlice =
    (
        initialState: State
    ): StateCreator<
        DialogState,
        [['zustand/devtools', never]],
        [],
        DialogState
    > =>
    (set) => ({
        ...initialState,
        setCharacter: (index: number, character?: characterTypes.Npc) =>
            set((state) => ({
                characterStates: state.characterStates.map(
                    (characterState, i) =>
                        i === index
                            ? { ...characterState, character }
                            : characterState
                ),
            })),
        setCharacterBackground: (background: string, index?: number) =>
            set((state) => ({
                characterStates: state.characterStates.map(
                    (characterState, i) =>
                        index === undefined || i === index
                            ? { ...characterState, background }
                            : characterState
                ),
            })),
        setDialogLines: (state: DialogLines) => set({ dialogLines: state }),
        setStartEndIndex: (index: number) => set({ startEndIndex: index }),
        save: () =>
            set((state) => {
                sessionStorage.setItem('dialogState', JSON.stringify(state))
                return state
            }),
        load: () => {
            const savedState = sessionStorage.getItem('dialogState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('dialogState')
        },
    })
