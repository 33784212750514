import { createContext, useState, ReactNode, Dispatch,SetStateAction  } from 'react';
import { AdminModel } from 'entities/admin';

// Default values for the context
const defaultClickCounts = {
  unrealGeppetto: 0,
  unityGeppetto: 0,
  videoGeppettoUnreal: 0,
  videoGeppettoUnity: 0,
  evilLord: 0,
};

const defaultUserAccessRights: AdminModel.User = {
  id: undefined,
  name: '',
  email: '',
  is_owner: false,
  is_admin: false,
  can_manage_models: false,
  can_manage_npcs: false,
  can_manage_datasets: false,
  can_download_gepetto: false,
  can_download_ariel: false,
  can_download_diagen: false,
  date_joined: '',
  valid_from: '',
  valid_until: '',
};

type ClickCountsType = typeof defaultClickCounts;

type AnimationContextType = {
  setUserAccessRights?: (value: AdminModel.User) => void;
  userAccessRights: AdminModel.User;
  isModalBestPracticeOpen?: boolean;
  setIsModalBestPracticeOpen?: (value: boolean) => void;
  isBestPracticeTriggered?: boolean;
  setIsBestPracticeTriggered?: (value: boolean) => void;
};

export const AnimationContext = createContext<AnimationContextType>({

  isModalBestPracticeOpen: false,
  userAccessRights: defaultUserAccessRights,
});

export const AnimationProvider = ({ children }: { children: ReactNode }) => {
  // States with default values
  const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false);
  const [isBestPracticeTriggered, setIsBestPracticeTriggered] = useState(false);
  const [userAccessRights, setUserAccessRights] = useState<AdminModel.User>(defaultUserAccessRights);

  return (
    <AnimationContext.Provider
      value={{
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        userAccessRights,
        setUserAccessRights,
        isBestPracticeTriggered,
        setIsBestPracticeTriggered,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};