import { createStore, StoreApi } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'
import { rollModel } from 'entities/roll'
import { createContext, useState } from 'react'

const initialRollState: rollModel.State = {
    selectedCharacter: undefined,
    selectedEmotion: undefined,
    rollRecords: [{
        npc_name: '',
        presets: '',
        temperature: 0,
        toxicity: 0,
        maxToken: 0,
        language: '',
        startState: '',
        question: '',
        response: '',
        prompt_name: '',
    }],
    dialogLines: [],
    startEndIndex: 0,
}
const rollStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'RollPage RollStore',
}

export const rollStore = createStore<rollModel.RollState>()(
    devtools(
        rollModel.createRollSlice(initialRollState),
        rollStoreDevtoolsOptions
    )
)

type RollContextType = {
    rollStore: StoreApi<rollModel.RollState>
    isModalRestartOpen: boolean
    setIsModalRestartOpen?: (value: boolean) => void
    isModalRegenerateOpen: boolean
    setIsModalRegenerateOpen?: (value: boolean) => void
    isModalExportOpen?: boolean
    setIsModalExportOpen?: (value: boolean) => void
    isRegenerateTriggered: boolean
    setIsRegenerateTriggered?: (value: boolean) => void
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isBestPracticeTrigger?: boolean
    setIsBestPracticeTrigger?: (value: boolean) => void
}

export const RollContext = createContext<RollContextType>({
    rollStore,
    isModalRestartOpen: false,
    isModalRegenerateOpen: false,
    isRegenerateTriggered: false,
    isModalBestPracticeOpen: false,
    isBestPracticeTrigger: false,
})

export const RollProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalRestartOpen, setIsModalRestartOpen] = useState(false)
    const [isModalRegenerateOpen, setIsModalRegenerateOpen] = useState(false)
    const [isModalExportOpen, setIsModalExportOpen] = useState(false)
    const [isRegenerateTriggered, setIsRegenerateTriggered] = useState(false)
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isBestPracticeTrigger, setIsBestPracticeTrigger] = useState(false)

    return (
        <RollContext.Provider
            value={{
                rollStore,
                isModalRestartOpen: isModalRestartOpen,
                setIsModalRestartOpen: setIsModalRestartOpen,
                isModalRegenerateOpen: isModalRegenerateOpen,
                setIsModalRegenerateOpen: setIsModalRegenerateOpen,
                isModalExportOpen: isModalExportOpen,
                setIsModalExportOpen: setIsModalExportOpen,
                isRegenerateTriggered: isRegenerateTriggered,
                setIsRegenerateTriggered: setIsRegenerateTriggered,
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isBestPracticeTrigger: isBestPracticeTrigger,
                setIsBestPracticeTrigger: setIsBestPracticeTrigger,
            }}
        >
            {children}
        </RollContext.Provider>
    )
}
