import { SectionAnimation } from 'widgets/animation'
import { AnimationProvider } from './animation-page.model'
import { HeaderLayout } from 'devlink'
import { PageHeaderAnimation, PopupAnimationBestPractice } from 'widgets/animation'

function AnimationPageContent() {

    return (
        <>
            <HeaderLayout
                slotHeader={<PageHeaderAnimation/>}
                slotHeaderLayout={<SectionAnimation />}
            />
                        <PopupAnimationBestPractice/>

        </>
    )
}

export function AnimationPage() {
    return (
        <AnimationProvider>
            <AnimationPageContent />
        </AnimationProvider>
    )
}