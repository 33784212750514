import { sessionModel } from 'entities/session';
import { Navigate, RouteObject, redirect } from 'react-router-dom';
import { pathKeys } from 'shared/lib/react-router';
import { characterStore } from 'entities/character/character.model';

export const homePageRoute: RouteObject = {
  path: pathKeys.home(),
  element: <Navigate to={pathKeys.ariel()} />,
  loader: async (args) => {
    if (!sessionModel.hasToken() || !sessionModel.isTokenValid()) {
      return redirect(pathKeys.login());
    }

    const currentTeam = characterStore.getState().currentTeam;
    if (currentTeam && (currentTeam.name.toLowerCase() === 'hydac' || currentTeam.name === 'Test')) {
        return redirect(pathKeys.corporatehelper());
    }

    return args;
  },
};