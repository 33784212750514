import { useMutation } from '@tanstack/react-query'
import { StoreApi } from 'zustand'
import { createRollRecordMutation } from './roll-record.api'
import { rollModel } from 'entities/roll'

const keys = {
    root: () => ['rollRecord'],
    createRollRecord: () => [...keys.root(), 'createRollRecord'],
}

export const useCreateRollRecordMutation = (
    rollStore: StoreApi<rollModel.State>
) => {
    return useMutation({
        mutationKey: keys.createRollRecord(),
        mutationFn: createRollRecordMutation,
        onSuccess: (data) => {
            const currentRollRecords = rollStore.getState().rollRecords || []
            // if any element in the currentRollRecords has the same entry as the data.start state, replace it
            if (currentRollRecords.some((record) => record.startState === data.startState)) {
                const updatedRollRecords = currentRollRecords.map((record) =>
                    record.startState === data.startState ? { ...record, response: data.response } : record
                )
                rollStore?.setState({
                    rollRecords: updatedRollRecords,
                })
                return
            } else {
                // if no element in the currentRollRecords has the same entry as the data.start state, add it
                rollStore?.setState({
                    rollRecords: [...currentRollRecords, data],
                })
            }
        },
    })
}
