import {
    PopupNotification,
    SectionClosed,
    SectionCopyNpc,
    SectionDataset,
} from 'devlink'
import { Team, characterStore } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import {
    useCopyNpc,
    useDeleteAudio,
    useDeleteNpc,
    useUploadAudio,
    useUploadImage,
    useUploadNpc,
    useUploadText,
    useUploadTrigger,
} from 'entities/db/db.queries'
import { emotionTypes } from 'entities/emotion'
import { sessionStore } from 'entities/session/session.model'
import { DatasetContext } from 'pages/dataset'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import {
    useInitSessionMutation,
    useDiagenFilesUploadMutation,
    useCreateStateChangesMutation,
} from 'entities/dialog-line/dialog-line.queries'
type DatasetGenerationContainerProps = {
    emotions: emotionTypes.Emotions
    characters: Npc[]
    selectedTeam: Team
    audios: any[]
    images: any[]
    onBackgroundChange?: (state: string) => void
}
type Options = {
    v: string
    t: string
}
type DeleteFilesState = {
    image: boolean
    audio: string[]
    trigger: boolean
    text: boolean
}

export function SectionDatasetContainer({
    emotions,
    characters,
    selectedTeam,
    images,
    audios,
    onBackgroundChange,
}: DatasetGenerationContainerProps) {
    return (
        <>
            <MyCharacters
                emotions={emotions}
                characters={characters}
                selectedTeam={selectedTeam}
                images={images}
                audios={audios}
            />
            <CopyNpc characters={characters} selectedTeam={selectedTeam} />
        </>
    )
}

function MyCharacters({
    emotions,
    characters,
    selectedTeam,
    images,
    audios,
}: DatasetGenerationContainerProps) {
    const initialEmotions = [
        { v: '0', t: 'neutral' },
        { v: '1', t: 'happy' },
        { v: '2', t: 'sad' },
        { v: '3', t: 'surprise' },
        { v: '4', t: 'angry' },
    ]
    const [emotionOptions, setEmotionOptions] =
        useState<Array<any>>(initialEmotions)
    const [playerName, setPlayerName] = useState('')
    const [background, setBackground] = useState('')
    const [uploadVoicesOptions, setUploadVoicesOptions] = useState<any>([
        { v: '0', t: 'neutral' },
    ])
    const [uploadVoiceFiles, setUploadVoiceFiles] = useState<any[]>([])
    const [cacheTimestamp, setCacheTimestamp] = useState<string>(
        new Date().getTime().toString()
    )
    const [image, setImage] = useState<File | null>(null)
    const [imageUrl, setImageUrl] = useState<string>('/images/default.png')
    const [trigger, setTrigger] = useState<File | null>(null)
    const [text, setText] = useState<File | null>(null)
    const [emailSent, setEmailSent] = useState(false)
    const [fileNameTopics, setFileNameTopics] = useState<string>('');
    const [fileNameEvents, setFileNameEvents] = useState<string>('');
    const [fileNameCharactersInfos, setFileNameCharactersInfos] = useState<string>('');

    const [fileTopics, setFileTopics] = useState<File>();
    const [fileEvents, setFileEvents] = useState<File>();
    const [fileCharactersInfos, setFileCharactersInfos] = useState<File>();
    const [getUploadFiles, setGetUploadFiles] = useState(false);
    const [audioFiles, setAudioFiles] = useState<any[]>()
    const [selectedEmotions, setSelectedEmotions] = useState<string[]>([])
    const [isNewCharacter, setIsNewCharacter] = useState(true)
    const [fastPreviewBox, setFastPreviewBox] = useState(false)
    const [trainingBox, setTrainingBox] = useState(false)
    const [email, setEmail] = useState<string>('')
    const [myCharacterSectionOpen, setMyCharacterSectionOpen] = useState(false)
    const [errorImageLength, setErrorImageLength] = useState('');
    const [deleteFiles, setDeleteFiles] = useState<DeleteFilesState>({
        image: true,
        audio: [],
        trigger: true,
        text: true,
    })

    const [characterOptions, setCharacterOptions] = useState<Options[]>()
    const { setIsModalLockedOpen } = useContext(DatasetContext)
    const [selectedCharacter, setSelectedCharacter] = useState<Npc | null>(null)
    const { mutate: uploadImage } = useUploadImage()
    const { mutate: deleteNpc } = useDeleteNpc()
    const { mutate: deleteAudio } = useDeleteAudio()
    const { mutate: uploadAudio } = useUploadAudio()
    const { mutate: uploadNpc } = useUploadNpc()

    const { mutate: initSession,
        isPending: isInitSessionPending,
        isSuccess: isInitSessionSuccess,
        isError: isInitSessionError,
    } = useInitSessionMutation()


    //upload files
    const { mutate: uploadDiagenFiles,
        isPending: isUploadDiagenFilesPending,
        isError: isUploadDiagenFilesError,
        isSuccess: isUploadDiagenFilesSuccess,
    } = useDiagenFilesUploadMutation()

    //callStateChanges
    const { mutate: callStateChanges,
        isPending: isCallStateChangesPending,
        isSuccess: isCallStateChangesSuccess,
        isError: isCallStateChangesError,
    } = useCreateStateChangesMutation()

    // //DiagenEvent
    // const { mutate: callDiagenEvent,
    //     isPending: isDiagenEventPending,
    //     isSuccess: isDiagenEventSuccess,
    //     isError: isDiagenEventError,
    // } = useCreateDiagenEventMutation()

    useLayoutEffect(() => {
        if (characters) {
            const newCharacters = characters.map((npc) => ({
                v: npc.id.toString(),
                t: npc.name,
            }))
            setCharacterOptions(newCharacters)
            if (
                characters.find((char) => char.name === selectedCharacter?.name)
            )
                return // if the character exists, dont switch
            setSelectedCharacter(characters[0])
        }
    }, [characters])

    useLayoutEffect(() => {
        if (selectedTeam) {
            setTrigger(null)
            setUploadVoiceFiles([null])
            setText(null)
            setPlayerName('')
            setBackground('')
            setEmotionOptions(initialEmotions)
            setIsNewCharacter(true)
            const newCharacters = characters.map((npc) => ({
                v: npc.id.toString(),
                t: npc.name,
            }))
            setCharacterOptions(newCharacters)
            setSelectedCharacter(characters[0])
        }
    }, [selectedTeam])

    useEffect(() => {
        if (!selectedCharacter) {
            setImageUrl('/images/default.png')
            return
        }
        setPlayerName(
            selectedCharacter.name === 'New Character'
                ? ''
                : selectedCharacter.name
        )
        setBackground(
            selectedCharacter.background === 'New Character'
                ? ''
                : selectedCharacter.background
        )

        setIsNewCharacter(selectedCharacter.name === 'New Character')
        const characterImage = images?.find(
            (image) => image.name === selectedCharacter.name
        )
        setImage(null)
        if (!image) {
            setImageUrl(
                `${characterImage?.image ?? '/images/default.png'}?nocache=${cacheTimestamp}`
            )
        }
        const characterAudio =
            audios?.filter(
                (audio) =>
                    audio.name === selectedCharacter.name &&
                    audio.description !== 'sample'
            ) ?? []
        const emotions = characterAudio.map((audio) =>
            audio.description.toLowerCase()
        )
        setUploadVoiceFiles(emotions)
        setSelectedEmotions(
            emotions.map(
                (emotion) =>
                    initialEmotions.find((option) => option.t === emotion)?.t ??
                    'neutral'
            )
        )
        const uploadVoicesOptions = emotions.map((emotion) => ({
            v: initialEmotions.find((option) => option.t === emotion)?.v ?? '0',
            t: emotion,
        }))
        setUploadVoicesOptions(
            uploadVoicesOptions.length > 0 ? uploadVoicesOptions : []
        )
        let tempAudio = audios.filter(
            (char) => char.name === selectedCharacter.name
        )

        // if uploadVoiceFile is type File, replace tempAudio index audio with the new file
        uploadVoiceFiles.forEach((voice, index) => {
            if (typeof voice === 'string') {
                return
            }
            tempAudio = tempAudio.map((audio, i) => {
                if (i === index) {
                    return {
                        ...audio,
                        // replace audio with a reference to the file to play it as a local url
                        audio: URL.createObjectURL(voice),
                    }
                }
                return audio
            })
        })

        setAudioFiles(tempAudio)
        setEmotionOptions(initialEmotions)
        setDeleteFiles({
            image: false,
            audio: [],
            trigger: false,
            text: false,
        })
    }, [selectedCharacter, images, audios])

    useLayoutEffect(() => {
        if (selectedEmotions) {
            // only set emotion options, that are not already selected
            const newEmotionOptions = emotionOptions.filter(
                (option) => !selectedEmotions.includes(option.t)
            )
            setEmotionOptions(newEmotionOptions)
        }
    }, [selectedEmotions])

    const resetCharacter = () => {
        setPlayerName('')
        setBackground('')
        setImage(null)
        setImageUrl('/images/default.png')
        setAudioFiles([])
        setEmotionOptions(initialEmotions)
        setText(null)
        setTrigger(null)
        setAudioFiles([])
        setUploadVoiceFiles([])
        setUploadVoicesOptions([])
        setSelectedEmotions([])
        setDeleteFiles({
            image: false,
            audio: [],
            trigger: false,
            text: false,
        })
        setSelectedCharacter(null)
    }

    //Delete character from the list
    const handleDeleteCharacter = (character: Options) => {
        const currentState = characterStore.getState()
        const updatedCharacters = characters.filter(
            (char) => char.name !== character.t && char.name !== 'New Character'
        )
        deleteNpc({
            team: selectedTeam?.name || '',
            npc: character.t,
        })
        const updatedImages = currentState.images?.filter(
            (image) => image.name !== character.t
        )
        const updatedAudios = currentState.audios?.filter(
            (audio) => audio.name !== character.t
        )

        resetCharacter()
        characterStore.setState({
            npcs: updatedCharacters,
            images: updatedImages,
            audios: updatedAudios,
        })
    }

    const handleDeleteNewCharacter = () => {
        resetCharacter()
    }

    //Upload data to the server
    const uploadData = (char: Npc) => {
        //if (DEBUG) return // dont upload data, if in debug mode
        let updatedCharacter = char
        let delFiles: DeleteFilesState = deleteFiles
        let updatedImages = images
        let updatedAudios = audios

        const timestamp = new Date().getTime() // Get current timestamp
        setCacheTimestamp(timestamp.toString())

        // always upload the new npc
        uploadNpc({
            team: selectedTeam?.name || '',
            npc: updatedCharacter.name,
            description: updatedCharacter.background,
            emotion: updatedCharacter.emotion,
            // language: updatedCharacter.language,
        })
        if (image) {
            uploadImage({
                file: image,
                team: selectedTeam?.name || '',
                npc: updatedCharacter.name,
            })
            updatedImages = [
                ...images.filter((image) => image.name !== updatedCharacter.name),
                {
                    name: updatedCharacter.name,
                    image: `https://custom-character-images.s3.amazonaws.com/${selectedTeam.name}/${updatedCharacter.name}/${updatedCharacter.name}.${image.name.split('.').pop()}`,
                },
            ]
        }

        uploadVoiceFiles.forEach((voice: File, index: number) => {
            if (voice) {
                if (deleteFiles.audio.includes(uploadVoicesOptions[index].t)) {
                    delFiles = {
                        ...deleteFiles,
                        audio: deleteFiles.audio.filter(
                            (audio) => audio !== uploadVoicesOptions[index].t
                        ),
                    }
                }

                if (typeof voice === 'string') {
                    return
                }
                uploadAudio({
                    file: voice,
                    team: selectedTeam?.name || '',
                    npc: updatedCharacter.name,
                    description: uploadVoicesOptions[index].t,
                })

                // only update the audio, if it does not exist yet
                if (
                    updatedAudios.find(
                        (audio) =>
                            audio.name === updatedCharacter.name &&
                            audio.description === uploadVoicesOptions[index].t
                    ) === undefined
                ) {
                    updatedAudios = updatedAudios.concat({
                        name: updatedCharacter.name,
                        audio: `https://custom-voices-data.s3.amazonaws.com/${selectedTeam.name}/${updatedCharacter.name}/${uploadVoicesOptions[index].t}.${voice.name.split('.').pop()}?nocache=${cacheTimestamp}`,
                        description: uploadVoicesOptions[index].t,
                    })
                } else {
                    // just add the cacheTimestamp to the audio if it already exists
                    updatedAudios = updatedAudios.map((audio) => {
                        if (
                            audio.name === updatedCharacter.name &&
                            audio.description === uploadVoicesOptions[index].t
                        ) {
                            return {
                                ...audio,
                                audio: `${audio.audio}?nocache=${cacheTimestamp}`,
                            }
                        }
                        return audio
                    })
                }
            }
        })

        if (delFiles.audio && delFiles.audio.length > 0) {
            delFiles.audio.forEach((emotion) => {
                deleteAudio({
                    team: selectedTeam?.name || '',
                    npc: updatedCharacter.name,
                    description: emotion,
                })

                // remove the audio from the updatedAudios
                updatedAudios = updatedAudios.filter(
                    (audio) =>
                        audio.name !== updatedCharacter.name || audio.description !== emotion
                )
            })
        }


        // update character store
        if (selectedCharacter?.name === 'New Character') {
            const newCharacter = {
                id: updatedCharacter.id,
                background: updatedCharacter.background,
                name: updatedCharacter.name,
                ariel_name: updatedCharacter.name,
                emotion: updatedCharacter.emotion,
                trigger_exists: updatedCharacter.trigger_exists,
                dataset_exists: updatedCharacter.dataset_exists,
                language: ['English'], //TODO: We should allow more languages at some point!
                preset: 0,
            }
            characterStore.setState({
                npcs: [
                    ...characters.filter(
                        (character) => character.name !== 'New Character'
                    ),
                    newCharacter,
                ],
                audios: updatedAudios,
                images: updatedImages,
            })

            setSelectedCharacter(newCharacter)
        } else {
            const updatedCharacters = characters
                .filter((char) => char.name !== 'New Character')
                .map((char) => {
                    if (char.name === updatedCharacter.name) {
                        return {
                            ...char,
                            id: updatedCharacter.id,
                            background: updatedCharacter.background,
                            name: char.name,
                            ariel_name: char.ariel_name,
                            emotion: updatedCharacter.emotion,
                            trigger_exists: updatedCharacter.trigger_exists,
                            dataset_exists: updatedCharacter.dataset_exists,
                            language: char.language,
                        }
                    }
                    return char
                })
            characterStore.setState({
                npcs: updatedCharacters,
                audios: updatedAudios,
                images: updatedImages,
            })
        }
    }

    //Save all data
    const handleSaveAllData = async () => {
        if (!playerName) {
            console.log('Player name is required')
            return
        }

        const existingCharacter = characters.find(
            (char) => char.name === playerName
        )

        // only use selected emotions, if we also have an audio file
        const addedEmotions = uploadVoiceFiles.map((voice, index) => {
            if (voice) {
                return uploadVoicesOptions[index].t
            }
            return ''
        }).filter((emotion) => emotion !== '')

        const newCharacter: Npc = {
            id: existingCharacter
                ? existingCharacter.id
                : characters.length + 1,
            name: playerName,
            background: background,
            trigger_exists: false,
            dataset_exists: false,
            emotion: addedEmotions,

            ariel_name: playerName,
            language: selectedCharacter?.language || ['en'],
            preset: 0,
        }

        uploadData(newCharacter)
    }

    //Remove emotion from the dropdown when already selected
    const handleEmotionChange = (index: string, selectedValue: string) => {
        const id = parseInt(index.split('-')[1])
        const newUploadVoices = [...uploadVoicesOptions]
        const newSelectedEmotions = [...selectedEmotions]
        const previousValue = newUploadVoices[id].t
        newUploadVoices[id] = emotionOptions.find(
            (option) => option.v === selectedValue
        ) || { v: '0', t: 'neutral' }

        setUploadVoicesOptions(newUploadVoices)
        newSelectedEmotions.splice(
            newSelectedEmotions.indexOf(previousValue),
            1
        )

        newSelectedEmotions.push(newUploadVoices[id].t)
        setEmotionOptions([
            ...emotionOptions,
            initialEmotions.find((option) => option.t === previousValue) || {
                v: '0',
                t: 'neutral',
            },
        ])
        setSelectedEmotions(newSelectedEmotions)
    }

    const handleAddVoice = () => {
        const availableEmotion = emotionOptions.find(
            (option) => !selectedEmotions.includes(option.t)
        )
        if (availableEmotion) {
            setUploadVoicesOptions([...uploadVoicesOptions, availableEmotion])
            setUploadVoiceFiles([...uploadVoiceFiles, null])
            setSelectedEmotions([...selectedEmotions, availableEmotion.t])
        }
    }

    const handleFileUploadTopics = (uploadedFileTopics: File) => {
        const fileExtension = uploadedFileTopics.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileTopics(uploadedFileTopics);
            setFileNameTopics(uploadedFileTopics.name);
        }
    };

    const handleFileUploadEvents = (uploadedFileEvents: File) => {
        const fileExtension = uploadedFileEvents.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileEvents(uploadedFileEvents);
            setFileNameEvents(uploadedFileEvents.name);
        }
    };

    const handleFileUploadCharactersInfos = (uploadedFileCharactersInfos: File) => {
        const fileExtension = uploadedFileCharactersInfos.name.split('.').pop();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileCharactersInfos(uploadedFileCharactersInfos);
            setFileNameCharactersInfos(uploadedFileCharactersInfos.name);
        }
    };


    useEffect(() => {
        const user = sessionStorage.getItem('user')
        if (user) {
            const { email } = JSON.parse(user)
            setEmail(email)
        }
    }, [])



    return (
        <>
            {!myCharacterSectionOpen ? (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setMyCharacterSectionOpen(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'My Character Section'}
                    subtitle={'Create your own character.'}
                />
            ) : (
                <div
                    onClick={() => {
                        if (selectedTeam?.can_manage_datasets) return
                        setIsModalLockedOpen?.(true)
                    }}
                >
                    <SectionDataset
                        disabled={!selectedTeam?.can_manage_datasets}
                        rpDropdownEmotions={{
                            initialemotions: initialEmotions,
                            label: 'Choose an emotion',
                            // options: emotionOptions,
                            onChange: (
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                                handleEmotionChange(
                                    e.target.id,
                                    e.target.value
                                ),
                        }}
                        rpDropdownCharacters={{
                            label: 'Choose your character',
                            options: characterOptions || [],
                            value: selectedCharacter?.id.toString(),
                            onChange: (
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                const selectedChar = characters.find(
                                    (char) =>
                                        char.id.toString() === e.target.value
                                )
                                if (selectedChar) {
                                    setSelectedCharacter(selectedChar)
                                }
                            },
                        }}
                        rpPlayerName={{
                            value: playerName,
                            onChange: (
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                const updatePlayerName = e.target.value
                                setPlayerName(updatePlayerName)
                            },
                            disabled:
                                !isNewCharacter ||
                                !selectedTeam?.can_manage_datasets,
                        }}
                        rpBackground={{
                            value: background,
                            onChange: (
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                const updatedbackground = e.target.value
                                setBackground(updatedbackground)
                            },
                        }}
                        fileNameEvents={fileNameEvents}
                        fileNameCharactersInfos={fileNameCharactersInfos}
                        fileNameTopics={fileNameTopics}
                        rpInitSession={{
                            onClick: (e: React.MouseEvent) => {
                                e.preventDefault();
                                initSession();
                             },
                            className: `button is-secondary ${isInitSessionPending && 'is-disabled '}`,
                        }}
                        rpUploadTopics={{
                            onClick: (e: React.MouseEvent) => {
                                e.preventDefault();
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = '.csv, .xls, .xlsx';
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target.files) {
                                        handleFileUploadTopics(target.files[0]);
                                    }
                                };
                                input.click();
                            },
                            onDragOver: (e: React.DragEvent) => {
                                e.preventDefault();
                            },
                            onDrop: (e: React.DragEvent) => {
                                e.preventDefault();
                                const droppedFileTopics = e.dataTransfer.files[0];
                                handleFileUploadTopics(droppedFileTopics);
                            },
                        }}
                        rpUploadEvents={{
                            onClick: (e: React.MouseEvent) => {
                                e.preventDefault();
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = '.csv, .xls, .xlsx';
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target.files) {
                                        handleFileUploadEvents(target.files[0]);
                                    }
                                };
                                input.click();
                            },
                            onDragOver: (e: React.DragEvent) => {
                                e.preventDefault();
                            },
                            onDrop: (e: React.DragEvent) => {
                                e.preventDefault();
                                const droppedFileEvents = e.dataTransfer.files[0];
                                handleFileUploadEvents(droppedFileEvents);
                            },
                        }}
                        rpUploadCharactersInformations={{
                            onClick: (e: React.MouseEvent) => {
                                e.preventDefault();
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = '.csv, .xls, .xlsx';
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target.files) {
                                        handleFileUploadCharactersInfos(target.files[0]);
                                    }
                                };
                                input.click();
                            },
                            onDragOver: (e: React.DragEvent) => {
                                e.preventDefault();
                            },
                            onDrop: (e: React.DragEvent) => {
                                e.preventDefault();
                                const droppedFileCharactersInfos = e.dataTransfer.files[0];
                                handleFileUploadCharactersInfos(droppedFileCharactersInfos);
                            },
                        }}
                        rpButtonSendFiles={{
                            onClick: (e: React.MouseEvent) => {
                                if (fileTopics && fileEvents && fileCharactersInfos) {
                                    uploadDiagenFiles({ diagenuploadPDF: { topics: fileTopics, diagenEvents: fileEvents, characterInformation: fileCharactersInfos } })
                                }
                            },
                        }}
                        rpImageUpload={{
                            ...(imageUrl && imageUrl !== '/images/default.png'
                                ? { exists: 'true' }
                                : {}),
                            filename: image ? image.name : '',
                            imageurl: imageUrl,
                            onClick: (e: Event) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = '.jpg,.png'
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement
                                    if (target.files) {
                                        const file = target.files[0]
                                        const imageTooBig = file.size > 5 * 1024 * 1024; // 5 MB in bytes
                                        if (imageTooBig) {
                                            setErrorImageLength('Image file is too large. Please upload a file that is 5 MB or smaller.');
                                            return;
                                        }
                                        setErrorImageLength('');    
                                        setImage(file)
                                        setImageUrl(URL.createObjectURL(file))
                                    }
                                }
                                input.click()
                            },
                        }}
                        rpImageDelete={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                setImage(null)
                                setImageUrl('/images/default.png')
                            },
                        }}

                        rpSaveAllData={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                handleSaveAllData()
                            },
                        }}
                        rpDeleteAllData={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                if (
                                    selectedCharacter &&
                                    selectedCharacter.name !== 'New Character'
                                ) {
                                    const deleteCharacter =
                                        characterOptions?.find(
                                            (option) =>
                                                option.t ===
                                                selectedCharacter?.name
                                        )
                                    if (deleteCharacter) {
                                        handleDeleteCharacter(deleteCharacter)
                                    }
                                }
                                // reset New Character
                                else handleDeleteNewCharacter()
                            },
                            className: `button is-large is-red ${selectedCharacter?.name === 'New Character' ? ' is-disabled' : ''}`,
                        }}
                        rdAddVoice={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();
                                if (!selectedTeam?.can_manage_datasets) return;
                                handleAddVoice();
                            },
                        }}
                        rpSendEmail={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                setEmailSent(true)
                            },
                        }}
                        rdRemoveLastVoice={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                if (uploadVoicesOptions.length > 1) {
                                    // update emotionOptions
                                    const removedEmotion =
                                        uploadVoicesOptions[
                                            uploadVoicesOptions.length - 1
                                        ].t
                                    const newEmotionOptions = [
                                        ...emotionOptions,
                                    ]
                                    newEmotionOptions.push(
                                        initialEmotions.find(
                                            (option) =>
                                                option.t === removedEmotion
                                        ) || { v: '0', t: 'neutral' }
                                    )
                                    setEmotionOptions(newEmotionOptions)
                                    setSelectedEmotions(
                                        selectedEmotions.filter(
                                            (emotion) =>
                                                emotion !== removedEmotion
                                        )
                                    )

                                    setDeleteFiles({
                                        ...deleteFiles,
                                        audio: deleteFiles.audio.concat(
                                            uploadVoicesOptions[
                                                uploadVoicesOptions.length - 1
                                            ].t
                                        ),
                                    })

                                    setUploadVoicesOptions(
                                        uploadVoicesOptions.slice(
                                            0,
                                            uploadVoicesOptions.length - 1
                                        )
                                    )
                                    setUploadVoiceFiles(
                                        uploadVoiceFiles.slice(
                                            0,
                                            uploadVoiceFiles.length - 1
                                        )
                                    )
                                }
                            },
                        }}
                        rpDeleteAudio={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                const index = parseInt(
                                    (e.target as HTMLButtonElement)?.id?.split(
                                        '-'
                                    )[1]
                                )
                                if (uploadVoicesOptions.length > 0) {
                                    // update emotionOptions
                                    const removedEmotion =
                                        uploadVoicesOptions[index].t
                                    const newEmotionOptions = [
                                        ...emotionOptions,
                                    ]
                                    newEmotionOptions.push(
                                        initialEmotions.find(
                                            (option) =>
                                                option.t === removedEmotion
                                        ) || { v: '0', t: 'neutral' }
                                    )

                                    setDeleteFiles((prevDeleteFiles) => {
                                        const updatedAudio =
                                            prevDeleteFiles.audio.includes(
                                                uploadVoicesOptions[index].t
                                            )
                                                ? prevDeleteFiles.audio
                                                : prevDeleteFiles.audio.concat(
                                                    uploadVoicesOptions[index]
                                                        .t
                                                )
                                        return {
                                            ...prevDeleteFiles,
                                            audio: updatedAudio,
                                        }
                                    })
                                    setEmotionOptions(newEmotionOptions)
                                    setSelectedEmotions(
                                        selectedEmotions.filter(
                                            (emotion) =>
                                                emotion !== removedEmotion
                                        )
                                    )
                                    setUploadVoicesOptions(
                                        uploadVoicesOptions.filter(
                                            (voice: any, i: any) => i !== index
                                        )
                                    )
                                    setUploadVoiceFiles(
                                        uploadVoiceFiles.filter(
                                            (voice: any, i: any) => i !== index
                                        )
                                    )
                                }
                            },
                        }}
                        rpVoices={{
                            preset: selectedCharacter?.preset === 1 || false,
                            upload: uploadVoicesOptions,
                            existing: audioFiles,
                        }}
                        errorImageLength={errorImageLength}
                        emailSent={emailSent}
                        emotionOptions={emotionOptions}
                        setUploadVoices={setUploadVoicesOptions}
                        uploadVoiceFiles={uploadVoiceFiles}
                        setUploadVoiceFiles={setUploadVoiceFiles}
                        rpCloseSection={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                setMyCharacterSectionOpen(false)
                            },
                        }}
                    />
                </div>
            )}
        </>
    )
}

type MultiSelectProps = {
    value: string
    label: string
}

function CopyNpc({
    characters,
    selectedTeam,
}: {
    characters: Npc[]
    selectedTeam: Team
}) {
    // Define State Variables
    const { setIsModalLockedOpen } = useContext(DatasetContext)
    const [teamOptions, setTeamOptions] = useState<MultiSelectProps[]>([])
    const [selectedTeams, setSelectedTeams] = useState<any[]>([])
    const [selectedCharacterOptions, setSelectedCharacterOptions] = useState<
        MultiSelectProps[]
    >([])
    const [copyCharacterSectionOpen, setCopyCharacterSectionOpen] =
        useState(false)
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    const [isCopied, setIsCopied] = useState(false);


    // API Calls
    const {
        mutate: copyNpc,
        isPending: copyNpcPending,
        isError: isErrorCopy,
        isSuccess: isSuccessCopy,
        error: errorCopy,
        data: dataCopy,
    } = useCopyNpc()

    // Layout Effects
    useLayoutEffect(() => {
        if (isErrorCopy) {
            openPopup(
                '\ue91e',
                'Error',
                JSON.parse(errorCopy?.response ?? '').detail ||
                'An error occurred while copying the character. Please try again later.'
            )
        }
    }, [isErrorCopy])

    useLayoutEffect(() => {
        if (isSuccessCopy && !copyNpcPending) {
            console.log('Success')
            // openPopup('\ue917', 'Success', 'The character has been successfully copied.')
        }
    }, [isSuccessCopy, copyNpcPending])

    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        const filteredTeams =
            currentState.teams?.filter(
                (team) => team.name !== selectedTeam?.name
            ) || []
        setTeamOptions(
            filteredTeams.map((team, index) => ({
                label: team.name,
                value: index.toString(),
            }))
        )
        setSelectedCharacterOptions([])
        setSelectedTeams([])
    }, [selectedTeam])

    const runCopyNpc = (
        characters: MultiSelectProps[],
        teams: MultiSelectProps[]
    ) => {
        teams.map((team) => {
            characters.map((char) => {
                while (copyNpcPending) {
                    // 1 second waiting
                    setTimeout(() => { }, 1000)
                }
                copyNpc({
                    team: selectedTeam?.name || '',
                    target_team: team.label,
                    npc: char.label,
                })
            })
        })
    }

    // Define functions
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    // If the user only has access to one team, do not show the content
    if (teamOptions.length === 0) {
        return null
    }

    return (
        <div
            onClick={() => {
                if (selectedTeam?.can_manage_datasets) return
                setIsModalLockedOpen?.(true)
            }}
        >
            {!copyCharacterSectionOpen ? (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setCopyCharacterSectionOpen(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Copy Character Section'}
                    subtitle={
                        'Copy your characters to one of your other teams.'
                    }
                />
            ) : (
                <SectionCopyNpc
                    disabled={
                        !selectedTeam?.can_manage_datasets || copyNpcPending
                    }
                    rpNpcDropdown={{
                        placeholder: 'Choose your npcs...',
                        isDisabled:
                            !selectedTeam?.can_manage_datasets ||
                            copyNpcPending,
                        value: selectedCharacterOptions,
                        options: characters
                            .filter((char) => char.name !== 'New Character')
                            .map((char) => ({
                                value: char.id.toString(),
                                label: char.name,
                            })),
                        onChange: (selectedOptions: any) => {
                            setSelectedCharacterOptions(selectedOptions)
                        },
                    }}
                    rpTargetTeamDropdown={{
                        placeholder: 'Choose your team',
                        isDisabled:
                            !selectedTeam?.can_manage_datasets ||
                            copyNpcPending,
                        value: selectedTeams,
                        options: teamOptions,
                        onChange: (selectedOptions: any) => {
                            setSelectedTeams(selectedOptions)
                        },
                    }}
                    rpCopyCharacters={{
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault()
                            if (!selectedTeam?.can_manage_datasets) return
                            runCopyNpc(selectedCharacterOptions, selectedTeams)
                            setIsCopied(true)
                            setTimeout(() => setIsCopied(false), 2000);
                        },
                    }}
                    rpCloseSection={{
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault()
                            setCopyCharacterSectionOpen(false)
                        },
                    }}
                />
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                        setIsCopied(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </div>
    )
}
