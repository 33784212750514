export function dialogBaseUrl1(path: string) {
    return `${(window as any).config.API_DIALOG_1}${path}`
}

export function dialogBaseUrl2(path: string) {
    return `${(window as any).config.API_DIALOG_2}${path}`
}

export function gamehelperBaseUrl(path: string) {
    return `${(window as any).config.API_GAMEHELPER}${path}`
}

export function logsBaseUrl(path: string) {
    return `${(window as any).config.API_LOGS}${path}`
}

export function diagenBaseUrl(path: string) {
    return `${(window as any).config.API_DIAGEN}${path}`
}

export function dbBaseUrl(path: string) {
    return `${(window as any).config.API_DB}${path}`
}

export function authBaseUrl(path: string) {
    return `${(window as any).config.API_AUTH}${path}`
}

export function arielBaseUrl(path: string) {
    return `${(window as any).config.API_ARIEL}${path}`
}

export const DEBUG = (window as any).config.DEBUG

export type { UnexpectedErrorDto } from './ximmersion.types'

