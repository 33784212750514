import { LoaderGenerating, PageHeaderCorporateHelper, PageHeaderGamehelper } from 'devlink'
import { characterQueries } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { CorporateHelperContext } from 'pages/corporatehelper/corporate-helper-page.model'
import { useContext, useLayoutEffect, useState } from 'react'
import { sessionStore } from '../../entities/session/session.model'

type TeamOption = {
    t: string
    v: string
}

export function PageHeaderCorporateHelperContainer() {
    const [isFormFilled, setIsFormFilled] = useState(false)
    const [teamOptions, setTeamOptions] = useState<TeamOption[]>([])
    const [currentTeam, setCurrentTeam] = useState<
        TeamOption | null | undefined
    >()
    const [showCopyPopup, setShowCopyPopup] = useState(false)
    const [isDropdownProcessing, setIsDropdownProcessing] = useState(false)

    const {
        setIsModalBestPracticeOpen,
    } = useContext(CorporateHelperContext)

  

    useLayoutEffect(() => {
        const currentState = characterStore.getState()
        if (currentTeam && currentState.currentTeam?.name !== currentTeam.t) {
            // getKeys()
            getNpcs()
            getImages()
            getAudios()
            return
        }
        if (!currentState.npcs){
            getNpcs()
        }
        if (!currentState.images){
            getImages()
        }
        if (!currentState.audios){
            getAudios()
        }
    }, [currentTeam])
    
    // const {
    //     mutate: getKeys,
    // } = sessionQueries.useGetKeysMutation(false)

    const { 
        mutate: getNpcs,
    } = characterQueries.useNpcsService()

    
    const { 
        mutate: getBackground,
    } = characterQueries.useBackgroundService()

    const { 
        mutate: getImages,
    } = characterQueries.useImageListService()

    const {
        mutate: getAudios,
    } = characterQueries.useAudioListService()

    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setTeamOptions(
            currentState.teams?.map((team, index) => ({
                t: team.name,
                v: index.toString(),
            })) || []
        )
    }, [])

    useLayoutEffect(() => {
        if (!teamOptions || teamOptions.length === 0) return
        const currentState = characterStore.getState()
        setCurrentTeam(
            teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                null
        )
    }, [teamOptions])

    const downloadFile = (path: string, filename: string) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <PageHeaderCorporateHelper
            rpBestButton={{
                className: `button is-secondary`,
                onClick: (e: Event) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(true)
                },
            }}
            rpTeamsDropdown={{
                
                value: currentTeam?.v || '',
                name: currentTeam?.t || '',
                options: teamOptions,
                onChange: (event: any) => {
                    const team = teamOptions.find(
                        (team) => team.v === event.target.value
                    )
                    // getKeys()
                    getNpcs()
                    getImages()
                    setCurrentTeam(team)
                    setIsDropdownProcessing(true) 
                    setTimeout(() => setIsDropdownProcessing(false), 2000) 
                    characterStore.getState().updateCurrentTeam(
                        sessionStore.getState().teams?.find(
                            (t) => t.name === team?.t
                        ) || ''
                    )

                },
            }}
            rpTemplateButton={{
                onClick: (e: Event) => {
                  e.preventDefault();
                  downloadFile('./corporateguide/corporatehelper.pdf', 'hydraulic_machines.pdf');
                },
              }}
            slotGenerationProcessing={
                isDropdownProcessing && <LoaderGenerating />
            }
            showCopyPopup={showCopyPopup}
            showDropdown={teamOptions.length > 1} 

        />
    )
}
