import {
    useMutation
} from '@tanstack/react-query'
import { characterStore } from 'entities/character/character.model'
import { useNavigate } from 'react-router-dom'
import { queryClient } from 'shared/lib/react-query'
import { pathKeys } from 'shared/lib/react-router'
import {
    forgetPasswordMutation,
    getKeysMutation,
    updateKeysMutation,
    updateUsageMutation,
    getTeamsMutation,
    getUsersMutation,
    loginUserMutation,
    registerMutation,
    resendMutation,
    verifyMutation
} from './session.api'
import { sessionStore } from './session.model'

const keys = {
    root: () => ['session'],
    currentUser: () => [...keys.root(), 'currentUser'] as const,
    createUser: () => [...keys.root(), 'createUser'] as const,
    loginUser: () => [...keys.root(), 'loginUser'] as const,
    registerUser: () => [...keys.root(), 'registerUser'] as const,
    forgetPassword: () => [...keys.root(), 'forgetPassword'] as const,
    resendCode: () => [...keys.root(), 'resendCode'] as const,
    updateUser: () => [...keys.root(), 'updateUser'] as const,
    getUsers: () => [...keys.root(), 'getUsers'] as const,
    deleteUser: () => [...keys.root(), 'deleteUser'] as const,
    teams: () => [...keys.root(), 'teams'] as const,
    keys: () => [...keys.root(), 'keys'] as const,
    usage: () => [...keys.root(), 'usage'] as const,
    verify: () => [...keys.root(), 'verify'] as const,
}

export function useLoginUserMutation (redirect: boolean = true) {
    return useMutation({
        mutationKey: keys.loginUser(),
        mutationFn: loginUserMutation,
        onSuccess: async (data: any) => {
            const currentState = sessionStore.getState()
            sessionStore.setState({
                ...currentState,
                token: data.AccessToken,
                expiresIn: Date.now() + data.ExpiresIn * 1000,
            })
        },
        onError: (error) => {
            console.log('error', error)
            sessionStore.setState({ token: null });
        },
    })
}

export function useGetTeamsMutation() {
        return useMutation({
        mutationKey: keys.teams(),
        mutationFn: getTeamsMutation,
        onSuccess: async (data: any) => {
            sessionStore.getState().updateTeams(data)
            characterStore.getState().updateCurrentTeam(data[0])
        },
        onError: (error) => {
            console.log('error teams', error)
            sessionStore.setState({ token: null });
        },
    })
}

export function useGetKeysMutation(redirect: boolean = true, admin: boolean = false) {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: keys.keys(),
        mutationFn: getKeysMutation,
        onSuccess: async (data: any) => {
            sessionStore.getState().updateKeys(data)
            redirect && (
                admin
                 ? navigate(pathKeys.admin()) : navigate(pathKeys.home())
            )

        },
        onError: (error) => {
            console.log('error keys', error)
            sessionStore.setState({ token: null });
        },
    })
}

export function useUpdateKeysMutation() {
    return useMutation({
        mutationKey: keys.keys(),
        mutationFn: updateKeysMutation,
        
    })
}

export function useUpdateUsageMutation() {
    return useMutation({
        mutationKey: keys.usage(),
        mutationFn: updateUsageMutation,
        
    })
}

export function useForgetPasswordMutation() {
    return useMutation({
        mutationKey: keys.forgetPassword(),
        mutationFn: forgetPasswordMutation
    })
}

export function useRegisterMutation() {
    return useMutation({
        mutationKey: keys.registerUser(),
        mutationFn: registerMutation,
    })
}

export function useGetUsersMutation() {
    return useMutation({
        mutationKey: keys.getUsers(),
        mutationFn: getUsersMutation,
    })
}

export function useVerifyMutation() {
    return useMutation({
        mutationKey: keys.verify(),
        mutationFn: verifyMutation
    })
}

export function useResendMutation() {
    return useMutation({
        mutationKey: keys.resendCode(),
        mutationFn: resendMutation
    })
}

export function useLogoutMutation() {
    const navigate = useNavigate()

    return useMutation({
        mutationKey: keys.deleteUser(),
        onSettled: async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            sessionStore.getState().updateToken(null)
            sessionStore.getState().updateKeys(null)
            sessionStore.getState().updateTeams(null)
            sessionStore.getState().updateExpiresIn(0)
            characterStore.getState().updateCurrentTeam(null)
            characterStore.getState().updateAudios(null)
            characterStore.getState().updateNpcs(null)
            characterStore.getState().updateImages(null)
            await queryClient.invalidateQueries()
            navigate(pathKeys.login())
        },
    })
}
