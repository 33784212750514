import { sessionModel } from 'entities/session'
import { gamehelperBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CreateGamehelperLineResponseSchema, CreateGamehelperUploadPDFResponseSchema } from './gamehelper.contracts'
import { mapCreateGamehelperLine, mapCreateGamehelperUpload } from './gamehelper.lib'
import { CreateGamehelperLineDto, CreateGamehelperUploadPdfSchema } from './gamehelper.types'


export async function createGamehelperUploadMutation(params: {
    gamehelperUploadPDF: CreateGamehelperUploadPdfSchema
}) {
    let body: FormData | string | undefined = undefined;
    body = new FormData();

    if (params.gamehelperUploadPDF.file) {
        body.append('file', params.gamehelperUploadPDF.file);
    }
    if (params.gamehelperUploadPDF.scanned_PDF) {
        body.append('scanned_PDF', params.gamehelperUploadPDF.scanned_PDF.toString());
    }
    if (params.gamehelperUploadPDF.best_response) {
        body.append('response', params.gamehelperUploadPDF.best_response);
    }

    return createFormMutation({
        request: {
            url: gamehelperBaseUrl(`/game-helper-upload-pdf`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
            body: body,
        },
        response: {
            contract: zodContract(CreateGamehelperUploadPDFResponseSchema),
            mapData: ({ best_response }) =>
                mapCreateGamehelperUpload(params.gamehelperUploadPDF, best_response),
        },
    })
}

export async function createGamehelperLineMutation(params: {
    gamehelperLine: CreateGamehelperLineDto
    onChunkReceived: (chunk: string) => void
}) {
    const body = new FormData();

    if (params.gamehelperLine.file_name) {
        body.append('file_name', params.gamehelperLine.file_name);
    }
    if (params.gamehelperLine.question) {
        body.append('question', params.gamehelperLine.question);
    }
    if (params.gamehelperLine.is_corporate) {
        body.append('is_corporate', params.gamehelperLine.is_corporate.toString());
    }
    const response = await fetch(gamehelperBaseUrl(`/game-helper-question-stream-v2`), {
        method: 'POST',
        headers: {
            Authorization: sessionModel.authorizationHeader({ useToken: false, system: 'diagen' }).Authorization || "Api-Key",
        },
        body: body,
    });

    if (!response.body) {
        throw new Error("No response body found");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value) {
            params.onChunkReceived(decoder.decode(value));
        }
    }
}