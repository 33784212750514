import { ChangeEvent, useLayoutEffect, useRef } from 'react'
import './slider-range.css'

type SliderRangeProps = {
    min?: number
    max?: number
    value?: number
    onChange: (value: number) => void
}

export function SliderRange({
    min = 0,
    max = 100,
    value = 100,
    onChange,
}: SliderRangeProps) {
    const sliderRef = useRef<HTMLInputElement>(null)

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        const target = e.target
        const min = target.min as unknown as number
        const max = target.max as unknown as number
        const val = parseInt(target.value)
        const percentage = ((val - min) * 100) / (max - min)
        target.style.backgroundSize = percentage + '% 100%' 
        onChange(val)
    }

    useLayoutEffect(() => {
        if (sliderRef.current) {
            const target = sliderRef.current
            const min = target.min as unknown as number
            const max = target.max as unknown as number
            const val = parseInt(target.value)
            const percentage = ((val - min) * 100) / (max - min)
            sliderRef.current.style.backgroundSize = percentage + '% 100%' 
            sliderRef.current.addEventListener('input', (event) => {
                handleInputChange(
                    event as unknown as ChangeEvent<HTMLInputElement>
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderRef.current])

    return (
        <div className="flex-center">
            <input
                ref={sliderRef}
                type="range"
                defaultValue={value}
                min={min}
                max={max}
                className="range_temperature"
            />
        </div>
    )
}
