import { CreateGamehelperLineDto, GamehelperLine, GamehelperUploadPdf, CreateGamehelperUploadPdfSchema } from './gamehelper.types'

export function mapCreateGamehelperLine(
    request: CreateGamehelperLineDto,
    response: string
): GamehelperLine {
    return {
        ...request,
        response: response ,
    }
}

export function mapCreateGamehelperUpload(
    request: CreateGamehelperUploadPdfSchema,
    best_response: string
): GamehelperUploadPdf {
    return {
        ...request,
        best_response: best_response ,
    }
}
