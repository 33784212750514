import { LogsLines, LogsUploadsPdf } from 'entities/loganalysis/logsanalysis.types'
import { StateCreator } from 'zustand'

export type State = {
    file: File
    file_name: string
    chunk_max_tokens: number
    top_k: number
    llama_road_params:  {
        n_gpu_layers: number
        n_ctx: number
        n_batch: number
        use_mmap: boolean
        main_gpu: number
        verbose: boolean
    }
    llama_generation_params: {
        max_tokens: number
    }
    logsLines: LogsLines
    logsUploadsPDF: LogsUploadsPdf
}

export type Actions = {
    setFile: (state: File) => void
    setFileName: (state: string) => void
    setChunkMaxTokens: (state: number) => void
    setTopK: (state: number) => void
    setLlamaRoadParams: (state: {
        n_gpu_layers: number
        n_ctx: number
        n_batch: number
        use_mmap: boolean
        main_gpu: number
        verbose: boolean
    }) => void
    setLlamaGenerationParams: (state: {
        max_tokens: number
    }) => void
    setLogsLines: (state: LogsLines) => void
    setLogsUploadsPDF: (state: LogsUploadsPdf) => void
}

export type LogsState = State & Actions

export const createLogsSlice =
    (
        initialState: State
    ): StateCreator<
    LogsState,
        [['zustand/devtools', never]],
        [],
        LogsState
    > =>
    (set) => ({
        ...initialState,
        setFile: (file) => set({ file }),
        setFileName: (file_name) => set({ file_name }),
        setChunkMaxTokens: (chunk_max_tokens) => set({ chunk_max_tokens }),
        setTopK: (top_k) => set({ top_k }),
        setLlamaRoadParams: (llama_road_params) => set({ llama_road_params }),
        setLlamaGenerationParams: (llama_generation_params) => set({ llama_generation_params }),
        setLogsLines: (logsLines) => set({ logsLines }),
        setLogsUploadsPDF: (logsUploadsPDF) => set({ logsUploadsPDF }),
    })
        