import { LogsAnalysis } from 'devlink';
import {
    useCreateLogsLineMutation,
    useCreateLogsUploadMutation}
from 'entities/loganalysis/logsanalysis.queries';
import { LogsContext } from 'pages/logsanalysis/logsanalysis-page.model';
import React, { useContext, useEffect, useState } from 'react';

type LogsAnalysisContainerProps = {
    isLoading?: boolean,
};

export function LogsAnalysisContainer({
    isLoading = false,
}: LogsAnalysisContainerProps) {

    interface LogsUploadInput {
        logsUploadPDF: {
            file: File;
            best_response: string;
            scanned_PDF: boolean;
        };
    }

    interface LogsLineInput {
        logsLine: {
            file_name: string;
            log_result: string;
        };
    }

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    });
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [answer, setAnswer] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>(''); 
    const [displayAnswer, setDisplayAnswer] = useState(false);
    const { logsStore } = useContext(LogsContext);

    let logResponse = "";

    const {
        mutate: createLogsLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
        isError: isErrorCreateLine,
        data: dataCreateLine,
        error: errorCreateLine,
    } = useCreateLogsLineMutation();

    const {
        mutate: createLogsUpload,
        isSuccess: isSuccessCreateUpload,
    } = useCreateLogsUploadMutation(logsStore);

    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        });
        setIsModalPopupOpen(true);
    };

    const handleFileUpload = (uploadedFile: File) => {
        const fileExtension = uploadedFile.name.split('.').pop();
        const allowedExtensions = ['txt'];
    
        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFile(uploadedFile);
            setFileName(uploadedFile.name); 

            createLogsUpload(
                { 
                    logsUploadPDF: {
                        file: uploadedFile,
                        best_response: '',
                        scanned_PDF: false
                    }
                },
                {
                    onSuccess: (data) => {
                        setFileContent(data?.best_response || ""); 
                        openPopup('\ue917', 'File uploaded successfully', 'You can now ask your question.');
                    },
                    onError: (error) => {
                        openPopup('\ue91e', 'Upload Error', 'Failed to upload the file. Please try again.');
                    }
                }
            );
        } else {
            openPopup('\ue91e', 'Invalid file type', 'Choose a valid file type.');
        }
    };

    const generateAnswer = async () => {
        logResponse = "";
        setAnswer("");
        if (fileName) { 
            try {
                const fileNameWithoutExtension = fileName.replace(/\.(txt)$/, ''); 
                createLogsLine(
                    { 
                        logsLine: {
                            file_name: fileNameWithoutExtension, 
                            // llama_load_params:  ({
                            //     n_gpu_layers: 24,
                            //     n_ctx: 8192,
                            //     n_batch: 64,
                            //     use_mmap: true,
                            //     main_gpu: 1,
                            //     verbose: false,
                            // }),
                        },
                            onChunkReceived: (chunk: string) => {
                                // Remove leading quotes from the chunk
                                const cleanedChunk = chunk.replace(/^"/, '');
                            
                                // update logsStore with the generated answer
                                logsStore.setState((state: any) => ({
                                    ...state,
                                    logsLines: [
                                        ...state.logsLines,
                                        {
                                            file_name: fileNameWithoutExtension,
                                            log_result: state.logsLines[0] + cleanedChunk + '\n',
                                        },
                                    ],
                                }));
                                logResponse += cleanedChunk;
                                setAnswer(logResponse);
                                setDisplayAnswer(true);
                        }
                    }
                )
            } catch (error) {
                openPopup('\ue91e', 'Error', 'An error occurred while generating the answer.');
            }
        } else {
            openPopup('\ue91e', 'Incomplete Data', 'Please ensure a file is uploaded and a question is provided.');
        }
    };

    useEffect(() => {
        if (isSuccessCreateLine) {
            console.log('Answer generated successfully', answer);
        } 
        if (isErrorCreateLine) {
            console.log('Error generating answer', errorCreateLine);
            openPopup('\ue91e', 'Error', 'Please try generating the answer again.');
        }
    }, [isSuccessCreateLine, isErrorCreateLine]);

    const handleDisplayAnswer = () => {
        if (!fileContent) {
            openPopup('\ue91e', 'No file uploaded', 'Please upload a file to generate an answer.');
            return;
        }
        generateAnswer();
    }

    return (
        <>
            <LogsAnalysis 
                fileName={fileName}
                isGeneratingLine={isGeneratingLine}
                isSuccessCreateLine={isSuccessCreateLine}
                isSuccessCreateUpload={isSuccessCreateUpload}
                rpFileUpload={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        const input = document.createElement('input');
                        input.type = 'file';
                        input.accept = '.txt';
                        input.onchange = (e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.files) {
                                handleFileUpload(target.files[0]);
                            }
                        };
                        input.click();
                    },
                    onDragOver: (e: React.DragEvent) => {
                        e.preventDefault();
                    },
                    onDrop: (e: React.DragEvent) => {
                        e.preventDefault();
                        const droppedFile = e.dataTransfer.files[0];
                        handleFileUpload(droppedFile);
                    },
                }}
                rpEnterButton={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        handleDisplayAnswer();
                    },
                }}
                result={
                        answer === ""
                        ? <span className="flex-center justify-center text-color-orange padding-vertical padding-xxxlarge">Generating logs...</span> 
                        : displayAnswer ? answer : <span className='flex-center justify-center padding padding-xxxlarge'>Please upload a .txt file and click on the button to see the answer here.</span> 
                }
                
            />
        </>
    );
}