import React, { useEffect, useState } from 'react';
import * as _Builtin from '../_Builtin';
import { ButtonIcon } from '../_Component/ButtonIcon';
import { ButtonText } from '../_Component/ButtonText';

export function ModifyTeam({
    as: _Component = _Builtin.Section,
    validFrom,
    validUntil,
    actualValidFrom,
    actualValidUntil,
    rdButtonModify,
    onClose,
    rpIcon = "\ue902",
}) {


    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small"
                tag="div"
            >
                <_Builtin.Block
                    className="ds-my-universe-wrapper padding-small rounded-top"
                    tag="div"
                >
                    <_Builtin.Block
                        className="section-title-wrapper margin-bottom margin-xsmall"
                        tag="div"
                    >
                        <_Builtin.Block
                            className="icon_ximm _2 margin-bottom margin-xxsmall cursor-pointer"
                            tag="div"
                            {...onClose}
                        >
                            {rpIcon}
                        </_Builtin.Block >
                        <_Builtin.Heading tag="h2">
                            {'Modify API access rights'}
                        </_Builtin.Heading>
                    </_Builtin.Block>
                </_Builtin.Block>

                <_Builtin.Block
                    className="batch-form section-data padding-xsmall background-blue-200 rounded-bottom"
                    tag="div"
                >
                    <_Builtin.Paragraph
                        className="margin-bottom margin-xxsmall"
                        htmlFor="field"
                    >
                        {'At the moment it is valid from'}                     <span style={{ color: 'orange' }}>
                            {actualValidFrom.split(' ')[0]}
                        </span> {'to'}                         <span style={{ color: 'orange' }}>
                            {actualValidUntil.split(' ')[0]}
                        </span>{'.'}  
                    </_Builtin.Paragraph>
                    <_Builtin.Paragraph
                        className="text-size-medium bold margin-vertical margin-small"
                        htmlFor="field"
                    >
                        {'Modify here:'}                    
                    </_Builtin.Paragraph>
                    <_Builtin.FormInlineLabel className='margin-left margin-xxsmall'>
                        {'Valid from: '}
                            {validFrom.value.split(' ')[0]}
                        <_Builtin.FormTextInput
                            type="date"
                            name="valid_from"
                            data-name="valid_from"
                            required={false}
                            id="valid_from"
                            form={{
                                type: 'valid_from-input',
                                name: 'valid_from',
                            }}
                            inputtype="custom"
                            className='background-blue-150 text-color-white'
                            {...validFrom}
                        />
                    </_Builtin.FormInlineLabel>
                    <_Builtin.FormInlineLabel className='margin-left margin-xlarge'>
                        {'Valid until: '}
                            {validUntil.value.split(' ')[0]}
                        <_Builtin.FormTextInput
                            type="date"
                            name="valid_until"
                            data-name="valid_until"
                            initialValue={actualValidUntil.split(' ')[0]}
                            required={false}
                            id="valid_until"
                            form={{
                                type: 'valid_until-input',
                                name: 'valid_until',
                            }}
                            inputtype="custom"
                            className='background-blue-150 text-color-white'
                            {...validUntil}
                        />
                    </_Builtin.FormInlineLabel>
                    <_Builtin.Block className="button-group" tag="div">
                        <_Builtin.Link
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rdButtonModify}

                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe914;" />
                                <ButtonText buttonText="Modify API Access" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    );
}