import { z } from 'zod'

export const CreateLogsLineResponseSchema = z.object({
    best_response: z.string(),
})

export const CreateLogsUploadPDFResponseSchema = z.object({
    best_response: z.string(),
})

export const CreateLogsLineDtoSchema = z.object({
    file_name: z.string(),
    // llama_load_params: z.object ({
    //     n_gpu_layers: z.number(),
    //     n_ctx: z.number(),
    //     n_batch: z.number(),
    //     use_mmap: z.boolean(),
    //     main_gpu: z.number(),
    //     verbose: z.boolean(),
    // }),
})

export const CreateLogsUploadPdfSchema = z.object({
    file: z.instanceof(File),
    scanned_PDF: z.boolean(),
    best_response: z.string(),
})

export const LogsUploadPdfSchema = z.object({
    file: z.instanceof(File),
    scanned_PDF: z.boolean(),
    best_response: z.string(),
})


export const LogsLineSchema = z.object({
    file_name: z.string(),
    // llama_load_params: z.object ({
    //     n_gpu_layers: z.number(),
    //     n_ctx: z.number(),
    //     n_batch: z.number(),
    //     use_mmap: z.boolean(),
    //     main_gpu: z.number(),
    //     verbose: z.boolean(),
    // }),
    best_response: z.string(),
})


export const LogsLinesSchema = z.array(LogsLineSchema)
export const LogsUploadsPdfSchema = z.array(LogsUploadPdfSchema)
