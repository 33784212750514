import { gamehelperModel } from 'entities/gamehelper';
import { createContext, useState, useContext } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'


const initialCorporateHelperState: gamehelperModel.State = {
    file: new File([''], ''),
    file_name: '',
    question: '',
    chunk_max_tokens: 0,
    top_k: 0,
    llama_road_params: {
        n_gpu_layers: 0,
        n_ctx: 0,
        n_batch: 0,
        use_mmap: false,
        main_gpu: 0,
        verbose: false,
    },
    llama_generation_params: {
        max_tokens: 0,
    },
    gamehelperLines: [],
    gamehelperUploadsPDF: [],
}

const corporatehelperStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'CorporateHelperPage CorporateHelperStore',
}



export const corporatehelperStore = createStore<gamehelperModel.GamehelperState>()(
    devtools(
        gamehelperModel.createGamehelperSlice(initialCorporateHelperState),
        corporatehelperStoreDevtoolsOptions
    )
)

type CorporateHelperContextType = {
    corporatehelperStore: StoreApi<gamehelperModel.State>
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isBestPracticeTriggered?: boolean
    setIsBestPracticeTriggered?: (value: boolean) => void
}

export const CorporateHelperContext = createContext<CorporateHelperContextType>({
    corporatehelperStore,
    isModalBestPracticeOpen: false,
})


export const CorporateHelperProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isBestPracticeTriggered, setIsBestPracticeTriggered] = useState(false)

    return (
        <CorporateHelperContext.Provider
            value={{
                corporatehelperStore,
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isBestPracticeTriggered: isBestPracticeTriggered,
                setIsBestPracticeTriggered: setIsBestPracticeTriggered,
            
            }}
        >
            {children}
        </CorporateHelperContext.Provider>
    )
}

export const useCorporateHelperContext = () => useContext(CorporateHelperContext);
