import { z } from 'zod'

export const CreateGamehelperLineResponseSchema = z.object({
    best_response: z.string(),
})

export const CreateGamehelperUploadPDFResponseSchema = z.object({
    best_response: z.string(),
})

export const CreateGamehelperLineDtoSchema = z.object({
    file_name: z.string(),
    question: z.string(),
    is_corporate: z.boolean().optional(),

})

export const CreateGamehelperUploadPdfSchema = z.object({
    file: z.instanceof(File),
    scanned_PDF: z.boolean(),
    best_response: z.string(),
})

export const GamehelperUploadPdfSchema = z.object({
    file: z.instanceof(File),
    scanned_PDF: z.boolean(),
    best_response: z.string(),
})


export const GamehelperLineSchema = z.object({
    file_name: z.string(),
    question: z.string(),
    is_corporate: z.boolean().optional(),
    response: z.string(),
})


export const GamehelperLinesSchema = z.array(GamehelperLineSchema)
export const GamehelperUploadsPdfSchema = z.array(GamehelperUploadPdfSchema)
