import { createContext } from 'react'

type DataUsageContextType = {
    visibility?: boolean
}

export const DataUsageContext = createContext<DataUsageContextType>({
    visibility: false,
})

export const DataUsageProvider = ({ children }: { children: React.ReactNode }) => {

    return (
        <DataUsageContext.Provider
            value={{ 

            }}
        >
            {children}
        </DataUsageContext.Provider>
    )
}
