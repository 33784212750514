import { createElement } from 'react'
import { RouteObject, redirect } from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'
import { AnimationPage } from './animation-page.ui'
import { sessionModel } from 'entities/session'

export const animationPageRoute: RouteObject = {
    path: pathKeys.demos(),
    element: createElement(AnimationPage),
    loader: async (args) => {
        if (!sessionModel.hasToken() || !sessionModel.isTokenValid()) {
            return redirect(pathKeys.login())
        }
        return args
    },
}
