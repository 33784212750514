import { sessionModel } from 'entities/session'
import { logsBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CreateLogsLineResponseSchema, CreateLogsUploadPDFResponseSchema } from './logsanalysis.contracts'
import { mapCreateLogsLine, mapCreateLogsUpload } from './logsanalysis.lib'
import { CreateLogsLineDto, CreateLogsUploadPdfSchema } from './logsanalysis.types'


export async function createLogsUploadMutation(params: {
    logsUploadPDF: CreateLogsUploadPdfSchema
}) {
    let body: FormData | string | undefined = undefined;
    body = new FormData();

    if (params.logsUploadPDF.file) {
        body.append('file', params.logsUploadPDF.file);
    }
    if (params.logsUploadPDF.scanned_PDF) {
        body.append('scanned_PDF', params.logsUploadPDF.scanned_PDF.toString());
    }
    if (params.logsUploadPDF.best_response) {
        body.append('best_response', params.logsUploadPDF.best_response);
    }

    return createFormMutation({
        request: {
            url: logsBaseUrl(`/game-logs-analysis-upload-file-v0`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
            body: body,
        },
        response: {
            contract: zodContract(CreateLogsUploadPDFResponseSchema),
            mapData: ({ best_response }) =>
                mapCreateLogsUpload(params.logsUploadPDF, best_response),
        },
    })
}

export async function createLogsLineMutation(params: {
    logsLine: CreateLogsLineDto
    onChunkReceived: (chunk: string) => void
}) {
    const body = new FormData();

    if (params.logsLine.file_name) {
        body.append('file_name', params.logsLine.file_name);
    }
    // if (params.logsLine.llama_load_params) {
    //     body.append('llama_load_params', params.logsLine.llama_load_params.toString());
    // }

    const response = await fetch(logsBaseUrl(`/game-logs-analysis-question-stream-v0`), {
        method: 'POST',
        headers: {
            Authorization: sessionModel.authorizationHeader({ useToken: false, system: 'diagen' }).Authorization || "Api-Key",
        },
        body: body,
    });

    if (!response.body) {
        throw new Error("No response body found");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value) {
            params.onChunkReceived(decoder.decode(value));
        }
    }
}