import { dialogModel } from 'entities/dialog'
import { StoreApi } from 'zustand'


export async function generateNextLine(
    createDialogLine: any,
    dialogStore: StoreApi<dialogModel.State>,
    params: {
        toxicity: number
        llama_generation_params: {
            max_tokens: number
            temperature: number
            stream: boolean
            stop: string[]
            top_p: number
            top_k: number
            frequency_penalty: number
            presence_penalty: number
        }
        language: string
    }
) {


    const state = dialogStore.getState()
    const previousLine = state.dialogLines.length
        ? state.dialogLines.at(-1)
        : null

    const npc =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[0].character
            : state.characterStates[1].character
    const player =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[1].character
            : state.characterStates[0].character

    const background =
        state.dialogLines.length % 2 === 0
        ? state.characterStates[0].background
        : state.characterStates[1].background

    // history
    let history = null
    if (state.dialogLines.length >= 3) {
        history = state.dialogLines.slice(-state.dialogLines.length, -1).map((line) => line.response)
    }
    if (state.dialogLines.length === 2) {
        history = state.dialogLines.slice(-2, -1).map((line) => line.response)
    }

    const randomVariance =  Math.random() * 0.3;
    const randomVarianceTOKEN = Math.random() * 20 - 10; 
    const randomVarianceToxicity =  Math.random() * 0.15; 


    const newTemperature = params.llama_generation_params.temperature + randomVariance; 
    let newMaxToken =  Math.round(params.llama_generation_params.max_tokens + randomVarianceTOKEN);
    let newToxicity =  Math.round((params.toxicity + randomVarianceToxicity) * 100) / 100;
    const newLineParams = {
        toxicity: Math.max(0, Math.min(1, newToxicity)), 
        question: previousLine?.response || '',
        llama_generation_params: {
            max_tokens: Math.max(10, Math.min(200, newMaxToken)),
            temperature: Math.max(0, Math.min(2, newTemperature)),
            stream: true,
            stop: params.llama_generation_params.stop,
            top_p: params.llama_generation_params.top_p,
            top_k: params.llama_generation_params.top_k,
            frequency_penalty: params.llama_generation_params.frequency_penalty,
            presence_penalty: params.llama_generation_params.presence_penalty,
        },
        npc_name: npc?.name || '',
        core_description: background || '',
        language: params.language,
        ...(history && { history }),
    }

    // Append the new dialog line to dialogStore
    dialogStore.setState((state) => ({
        ...state,
        dialogLines: [
            ...state.dialogLines,
            {
                ...newLineParams,
                response: '',
                speaker: npc,
            },
        ],
    }))

    createDialogLine({ 
        dialogLine: newLineParams,
        onChunkReceived: (chunk: string) => {
            dialogStore.setState((state) => ({
                ...state,
                dialogLines: state.dialogLines.map((line, index) =>
                    index === state.dialogLines.length - 1
                        ? { ...line, response: line.response + chunk }
                        : line
                ),
            }))
        }
     })
}