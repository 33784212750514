import { CreateLogsLineDto, CreateLogsUploadPdfSchema, LogsLine, LogsUploadPdf } from './logsanalysis.types'

export function mapCreateLogsLine(
    request: CreateLogsLineDto,
    best_response: string
): LogsLine {
    return {
        ...request,
        best_response: best_response ,
    }
}

export function mapCreateLogsUpload(
    request: CreateLogsUploadPdfSchema,
    best_response: string
): LogsUploadPdf {
    return {
        ...request,
        best_response: best_response ,
    }
}
