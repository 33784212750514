import React, { useState, useEffect } from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export function PopupRollBestPractices({
    as: _Component = _Builtin.Block,
    rpClosePopUp = {},
    rpButtonCancel = {},
    visibility = true,
}) {

    const images = [
        {
            src: "/images/bestpractice/rollbestpractice1.png",
            alt: "X&Immersion - BEST PRACTICE ROLL 1",
        },
        {
            src: "/images/bestpractice/rollbestpractice2.png",
            alt: "X&Immersion - BEST PRACTICE ROLL 2",
        },
        {
            src: "/images/bestpractice/rollbestpractice3.png",
            alt: "X&Immersion - BEST PRACTICE ROLL 3",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        const isLastImage = currentIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToPrevious = () => {
        const isFirstImage = currentIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            } else if (event.key === 'ArrowRight') {
                goToNext();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    const saveImage = () => {
        images.forEach((image) => {
            const link = document.createElement('a');
            link.href = image.src;
            link.download = image.alt;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return visibility ? (
        <_Component className="popup-overlay-best" tag="div">
            <_Builtin.Block
                className="popup-box-best margin-medium rounded-8 overflow-scroll-navbar"
                tag="div"
            >
                <_Builtin.Block
                    className="popup-top-best background-color-blue200 padding-xsmall"
                    tag="div"
                    {...rpClosePopUp}
                >
                    <_Builtin.Block
                        className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
                        tag="div"
                    >
                        {""}
                    </_Builtin.Block>
                    <_Builtin.Heading className="text-align-center" tag="h2">
                        {"Best Practice for Roll a Dice"}
                    </_Builtin.Heading>

                    <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
                        {"\ue91e"}
                    </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block
                    className="popup-body-best padding padding-medium background-blue-150 z-index-1"
                    tag="div"

                >
                    <_Builtin.Paragraph className="flex-center  padding-horizontal">
                        {
                            "To better utilize the roll page of the web app:"
                        }
                    </_Builtin.Paragraph>
                    <_Builtin.Block className="button-group padding-vertical padding-xxsmall" tag="div" {...rpButtonCancel}>
                        <_Builtin.Link
                            className="button is-small is-secondary"
                            button={false}
                            block="inline"
                            onClick={saveImage}
                            options={{
                                href: "#",
                            }}

                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe908;" />
                                <ButtonText buttonText="Save the best practices" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                    <_Builtin.Image
                        className="imgpopup"
                        loading="lazy"
                        width="auto"
                        height="auto"
                        src={images[currentIndex].src}
                        alt={images[currentIndex].title}
                    />
                    <_Builtin.Block className="flex-center margin-top margin-tiny" tag="div">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            onClick={goToPrevious}
                            className="cursor-pointer margin-right margin-small arrow-carousel"
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            onClick={goToNext}
                            className='cursor-pointer arrow-carousel'
                        />
                    </_Builtin.Block>
                    <_Builtin.Block className="button-group margin-top margin-xxsmall" tag="div" {...rpButtonCancel}>
                        <_Builtin.Link
                            className="button is-large is-transparent"
                            button={false}
                            block="inline"
                            options={{
                                href: "#",
                            }}

                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="" />
                                <ButtonText buttonText="Cancel" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    ) : null;
}
