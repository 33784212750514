import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'shared/lib/react-query'
import { sessionStore } from '../session/session.model'
import { CreateGetTagsMutation, CreateUploadDiagenFilesMutation, createCallStateChangesMutation, createDiagenEventMutation, createDialogLineMutation, createInitSessionMutation, createPromptMutation, createTopicDetectionMutation, createUpdateHistoryMutation } from './dialog-line.api'
import { DialogLine } from './dialog-line.types'

const keys = {
    root: () => ['dialogLine'],
    initsession: () => [...keys.root(), 'initsession'],
    dialogLine: () => [...keys.root(), 'dialogLine'],
    createDialogLine: () => [...keys.root(), 'createDialogLine'] as const,
    createDiagenFilesUpload: () => [...keys.root(), 'createDiagenFilesUpload'] as const,
    createPrompt: () => [...keys.root(), 'createPrompt'] as const,
    createStateChanges: () => [...keys.root(), 'createStateChanges'] as const,
    createDiagenEvents: () => [...keys.root(), 'createDiagenEvents'] as const,
    createTopicDetection: () => [...keys.root(), 'createTopicDetection'] as const,
    updateHistory: () => [...keys.root(), 'updateHistory'] as const,
    getTags: () => [...keys.root(), 'getTags'] as const,
}

type StreamRequestBody = {
    npc_name: string
    question: string
    core_description: string
    llama_load_params: {
        n_gpu_layers: number
        n_ctx: number
        use_mmap: boolean
        main_gpu: number
        verbose: boolean
    }
    llama_generation_params: {
        temperature: number
        max_tokens: number
        stream: boolean
        stop: string[]
        top_p: number
        top_k: number
        frequency_penalty: number
        presence_penalty: number
    }
    toxicity: number
    background: string
    language: string
    history?: string[]
}

type TopicDetectionType = {
    question: string
    npc_name: string
    model_path: string
    llama_generation_params: {
        temperature: number
        max_tokens: number
        top_p: number
    }
    llama_load_params: {
        n_gpu_layers: number
        n_ctx: number
        main_gpu: number
    }
}

export const dialogLineService = {
    queryKey: () => keys.dialogLine(),

    getCache: () =>
        queryClient.getQueryData<DialogLine>(dialogLineService.queryKey()),

    setCache: (dialogLine: DialogLine) =>
        queryClient.setQueryData(dialogLineService.queryKey(), dialogLine),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: dialogLineService.queryKey(),
        }),
}

export function useDiagenFilesUploadMutation() {
    return useMutation({
        mutationKey: keys.createDiagenFilesUpload(),
        mutationFn: CreateUploadDiagenFilesMutation,
    })
}

export function useCreateStateChangesMutation() {
    return useMutation({
        mutationKey: keys.createStateChanges(),
        mutationFn: createCallStateChangesMutation,
    })
}

export function useCreateDiagenEventMutation() {
    return useMutation({
        mutationKey: keys.createDiagenEvents(),
        mutationFn: createDiagenEventMutation,
    })
}

export function useUpdateHistoryMutation() {
    return useMutation({
        mutationKey: keys.updateHistory(),
        mutationFn: createUpdateHistoryMutation,
    })
}

export function useCreatePromptMutation() {
    return useMutation({
        mutationKey: keys.createPrompt(),
        mutationFn: createPromptMutation,
    })
}

export function useGetTagsMutation() {
    return useMutation({
        mutationKey: keys.getTags(),
        mutationFn: CreateGetTagsMutation,
        onSuccess: async (data: any) => {
            const currentState = sessionStore.getState()
            sessionStore.setState({
                ...currentState,
                stateTags: data.stateTags,
            })
        },
        onError: (error) => {
            const currentState = sessionStore.getState()
            sessionStore.setState({
                ...currentState,
                stateTags: [],
            })
        },
    })
}


export function useInitSessionMutation () {
    return useMutation({
        mutationKey: keys.initsession(),
        mutationFn: createInitSessionMutation,
        onSuccess: async (data: any) => {
            const currentState = sessionStore.getState()
            sessionStore.setState({
                ...currentState,
                session_id: data.session_id,
                // stateTags: data.stateTags,
            })
        },
        onError: (error) => {
            const currentState = sessionStore.getState()
            sessionStore.setState({
                ...currentState,
                session_id: null,
                stateTags: [],
            })
        },
    })
}

export function useCreateTopicDetectionMutation(
) {
    return useMutation<
        void,
        Error,
        {
            topicDetection: TopicDetectionType
            onChunkReceived: (chunk: string) => void
        }
    >({
        mutationKey: keys.createTopicDetection(),
        mutationFn: ({ topicDetection, onChunkReceived }) =>
            createTopicDetectionMutation({
                topicDetection,
                onChunkReceived,
            }),
    })
}

export function useCreateDialogLineMutation(
) {
    return useMutation<
        void,
        Error,
        {
            dialogLine: StreamRequestBody
            onChunkReceived: (chunk: string) => void
        }
    >({
        mutationKey: keys.createDialogLine(),
        mutationFn: ({ dialogLine, onChunkReceived }) =>
            createDialogLineMutation({
                dialogLine,
                onChunkReceived,
            }),
    })
}

export function useRegenerateDialogLineMutation(
) {
    return useMutation<
        void,
        Error,
        {
            dialogLine: StreamRequestBody
            onChunkReceived: (chunk: string) => void
        }
    >({
        mutationKey: keys.createDialogLine(),
        mutationFn: ({ dialogLine, onChunkReceived }) =>
            createDialogLineMutation({
                dialogLine,
                onChunkReceived,
            }),
    })
}
