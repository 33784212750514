import { useLayoutEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import * as _Builtin from '../_Builtin'

createTheme(
    'createTableTheme',
    {
        text: {
            primary: '#f7f7f7',
            secondary: '#727eab',
        },
        background: {
            default: '#2b3162',
        },
        striped: {
            default: '#C62828',
            selected: '#C62828',
        },
        context: {
            background: '#C62828',
            text: '#C62828',
        },
        divider: {
            default: '#878b9b',
        },
        highlightOnHover: {
            default: '#404a8e',
            text: '#f7f7f7',
        },

    },
    'dark'
)

export function DataTeam({
    as: _Component = _Builtin.Section,
    selectedKeys,
    selectedTeam,
}) {

    const [dataTop, setDataTop] = useState([]);
    const [dataBottom, setDataBottom] = useState([]);
    const [columnsTop, setColumnsTop] = useState([]);
    const [columnsBottom, setColumnsBottom] = useState([]);
    const [totalsTop, setTotalsTop] = useState({ diagen: 0, ariel: 0, gepetto: 0 });
    const [totalsBottom, setTotalsBottom] = useState({ diagen: 0, ariel: 0, gepetto: 0 });

    useLayoutEffect(() => {
        const optionsTable = [];

        // Process data usage (Diagen, Ariel, Gepetto)
        if (selectedKeys.diagen) {
            selectedKeys.diagen.usage.forEach((usage) => {
                if (!optionsTable.some((option) => option.date === usage.date)) {
                    optionsTable.push({
                        date: usage.date,
                        diagen: usage.usage,
                        ariel: 0,
                        gepetto: 0,
                    });
                } else {
                    optionsTable.find((option) => option.date === usage.date).diagen = usage.usage;
                }
            });
        }

        if (selectedKeys.ariel) {
            selectedKeys.ariel.usage.forEach((usage) => {
                if (!optionsTable.some((option) => option.date === usage.date)) {
                    optionsTable.push({
                        date: usage.date,
                        diagen: 0,
                        ariel: usage.usage,
                        gepetto: 0,
                    });
                } else {
                    optionsTable.find((option) => option.date === usage.date).ariel = usage.usage;
                }
            });
        }

        if (selectedKeys.gepetto) {
            selectedKeys.gepetto.usage.forEach((usage) => {
                if (!optionsTable.some((option) => option.date === usage.date)) {
                    optionsTable.push({
                        date: usage.date,
                        diagen: 0,
                        ariel: 0,
                        gepetto: usage.usage,
                    });
                } else {
                    optionsTable.find((option) => option.date === usage.date).gepetto = usage.usage;
                }
            });
        }

        const columns = [
            { name: 'Date', selector: (row) => row.date.replace('-01', ''), sortable: true },
            { name: 'Diagen', selector: (row) => row.diagen, sortable: true },
            { name: 'Ariel', selector: (row) => row.ariel, sortable: true },
            { name: 'Geppetto', selector: (row) => row.gepetto, sortable: true },
        ];

        setColumnsTop(columns);
        setDataTop(optionsTable);

        // Calculate totals
        const totals = optionsTable.reduce(
            (acc, row) => ({
                diagen: acc.diagen + row.diagen,
                ariel: acc.ariel + row.ariel,
                gepetto: acc.gepetto + row.gepetto,
            }),
            { diagen: 0, ariel: 0, gepetto: 0 }
        );
        setTotalsTop(totals);
    }, [selectedKeys]);

    useLayoutEffect(() => {
        const optionsTable = [];

        // Process plugin downloads
        if (selectedKeys.diagen) {
            selectedKeys.diagen.download.forEach((download) => {
                if (!optionsTable.some((option) => option.date === download.date)) {
                    optionsTable.push({
                        date: download.date,
                        diagen: download.usage,
                        ariel: 0,
                        gepetto: 0,
                    });
                } else {
                    optionsTable.find((option) => option.date === download.date).diagen = download.usage;
                }
            });
        }

        if (selectedKeys.ariel) {
            selectedKeys.ariel.download.forEach((download) => {
                if (!optionsTable.some((option) => option.date === download.date)) {
                    optionsTable.push({
                        date: download.date,
                        diagen: 0,
                        ariel: download.usage,
                        gepetto: 0,
                    });
                } else {
                    optionsTable.find((option) => option.date === download.date).ariel = download.usage;
                }
            });
        }

        if (selectedKeys.gepetto) {
            selectedKeys.gepetto.download.forEach((download) => {
                if (!optionsTable.some((option) => option.date === download.date)) {
                    optionsTable.push({
                        date: download.date,
                        diagen: 0,
                        ariel: 0,
                        gepetto: download.usage,
                    });
                } else {
                    optionsTable.find((option) => option.date === download.date).gepetto = download.usage;
                }
            });
        }

        const columns = [
            { name: 'Date', selector: (row) => row.date.replace('-01', ''), sortable: true },
            { name: 'Diagen', selector: (row) => row.diagen, sortable: true },
            { name: 'Ariel', selector: (row) => row.ariel, sortable: true },
            { name: 'Geppetto', selector: (row) => row.gepetto, sortable: true },
        ];

        setColumnsBottom(columns);
        setDataBottom(optionsTable);

        // Calculate totals
        const totals = optionsTable.reduce(
            (acc, row) => ({
                diagen: acc.diagen + row.diagen,
                ariel: acc.ariel + row.ariel,
                gepetto: acc.gepetto + row.gepetto,
            }),
            { diagen: 0, ariel: 0, gepetto: 0 }
        );
        setTotalsBottom(totals);
    }, [selectedKeys]);

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small rounded-8"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall rounded-8"
                    tag="div"
                >
                    <_Builtin.Heading tag="h2">
                        {'Data Usage'}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <DataTable
                    className="dropdown"
                    columns={columnsTop}
                    data={dataTop}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    pointerOnHover
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    theme="createTableTheme"
                />
                                <_Builtin.Block className="content-centered margin-top margin-small">
                    <_Builtin.Paragraph >
                        <strong>Totals:</strong> Diagen: <span className='text-color-orange'>{totalsTop.diagen}</span> - Ariel: <span className='text-color-orange'>{totalsTop.ariel}</span> - Geppetto: <span className='text-color-orange'>{totalsTop.gepetto}</span>
                    </_Builtin.Paragraph>
                </_Builtin.Block>
                   <_Builtin.Heading className='margin-top margin-small' tag="h2">
                        {'Plug-in Downloads'}
                    </_Builtin.Heading>
                <DataTable
                    className="dropdown"
                    columns={columnsBottom}
                    data={dataBottom}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    pointerOnHover
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    theme="createTableTheme"
                />
                                <_Builtin.Block className="content-centered margin-top margin-small">
                    <_Builtin.Paragraph >
                        <strong>Totals:</strong> Diagen: <span className='text-color-orange'>{totalsBottom.diagen}</span> - Ariel: <span className='text-color-orange'>{totalsBottom.ariel}</span> - Geppetto: <span className='text-color-orange'>{totalsBottom.gepetto}</span>
                    </_Builtin.Paragraph>
                </_Builtin.Block>

            </_Builtin.Block>
        </_Component>
    )
}
