import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'


export function IntegrationUnityModules({
    as: _Component = _Builtin.Section,
    rpButtonUnityArielDownload,
    rpButtonUnityGepettoDownload,
    rpUserAccessRights,

}) {
    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className='container-large'
                tag="div"
            >
                <_Builtin.Block className='background-color-blue150 rounded-8' tag="div">
                    <_Builtin.Block className="animation-cards flex-center" tag="div">
                        <_Builtin.Block className="textposition" tag="div">
                            <_Builtin.Block className="logoposition" tag="div">
                                <_Builtin.Image
                                    className="logo-cover"
                                    id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                    loading="lazy"
                                    width="auto"
                                    height="auto"
                                    alt=""
                                    src='/images/logo/unity.png'
                                />
                            </_Builtin.Block>
                            <_Builtin.Heading className='flex-center'>
                            {"Plug-Ins for Unity"}
                            </_Builtin.Heading>
                            <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                {"Here you can download the Ariel voice plug-in or the Geppetto animation plug-in directly from this interface for your Unity software."}
                            </_Builtin.Paragraph>
                        </_Builtin.Block>
                    </_Builtin.Block>

                    <_Builtin.Block className="container-large padding padding-small">
                        <_Builtin.Block className="grid-2col" tag="div">
                            <>
                                <_Builtin.Block tag="div">
                                    <_Builtin.Block
                                        className="light-border rounded-8"
                                        tag="div"
                                    >
                                        <_Builtin.Block
                                            className="integration-cards grid-2col background-color-blue200 rounded-8"
                                            tag="div"
                                        >
                                            <_Builtin.Block
                                                className="logointegration"
                                                tag="div"
                                            >
                                                <_Builtin.Image
                                                    className="logo-cover-integration"
                                                    width="auto"
                                                    height="auto"
                                                    alt=""
                                                    src="/images/icon/arielicon.png"
                                                />
                                            </_Builtin.Block>
                                            <_Builtin.Block
                                                className="textintegration"
                                                tag="div"
                                            >
                                                <_Builtin.Paragraph className="flex-center text-weight-semibold text-size-large">
                                                    {'Ariel:'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                                    {'Voice Plugin'}
                                                </_Builtin.Paragraph>
                                                {rpUserAccessRights &&
                                                    rpUserAccessRights.can_download_ariel ===
                                                    1 ? (
                                                    <>

                                                        <_Builtin.Block
                                                            className="grid-2col flex-center margin-top margin-small"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer margin-vertical margin-xxlarge"
                                                                {...rpButtonUnityArielDownload}
                                                            >
                                                                {'Unity 2020+'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnityArielDownload}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                    
                                                    </>
                                                ) : (
                                                    <_Builtin.Block className="textposition margin-vertical margin-xxlarge">
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'You want to get access to Ariel plugin?' 
                                                        }
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'Contact us at '
                                                        }
                                                                    <a href="mailto:bastien.s@xandimmersion.com">
                bastien.s@xandimmersion.com
            </a>
            {
                                                            ' and someone from our team will answer to you!'
                                                        }
                                                    </_Builtin.Paragraph>
                                            </_Builtin.Block>
                                                )}
                                            </_Builtin.Block>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                </_Builtin.Block>
                                <_Builtin.Block tag="div">
                                    <_Builtin.Block
                                        className="light-border rounded-8"
                                        tag="div"
                                    >
                                        <_Builtin.Block
                                            className="integration-cards background-color-blue200 rounded-8"
                                            tag="div"
                                        >
                                            <_Builtin.Block
                                                className="logointegration"
                                                tag="div"
                                            >
                                                <_Builtin.Image
                                                    className="logo-cover-integration"
                                                    width="auto"
                                                    height="auto"
                                                    alt=""
                                                    src="/images/icon/animationicon.png"
                                                />
                                            </_Builtin.Block>
                                            <_Builtin.Block
                                                className="textintegration"
                                                tag="div"
                                            >
                                                <_Builtin.Paragraph className="flex-center text-weight-semibold text-size-large">
                                                    {'Geppetto:'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                                    {'Animation Plugin'}
                                                </_Builtin.Paragraph>
                                                {rpUserAccessRights &&
                                                    rpUserAccessRights.can_download_gepetto === 1 ? (
                                                    <>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center margin-top margin-small"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer margin-vertical margin-xxlarge"
                                                                {...rpButtonUnityGepettoDownload}
                                                            >
                                                                {'Unity 2020+'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnityGepettoDownload}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                    </>) : (
                                                    <_Builtin.Block className="textposition margin-vertical margin-xxlarge">
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'You want to get access to Geppetto plugin?' 
                                                        }
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'Contact us at '
                                                        }
                                                                    <a href="mailto:bastien.s@xandimmersion.com">
                bastien.s@xandimmersion.com
            </a>
            {
                                                            ' and someone from our team will answer to you!'
                                                        }
                                                    </_Builtin.Paragraph>
                                            </_Builtin.Block>
                                                )}
                                            </_Builtin.Block>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                </_Builtin.Block>

                            </>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
