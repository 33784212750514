import { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';


type IntegrationContextType = {
    visibility?: boolean
    isModalLockedOpen?: boolean
    setIsModalLockedOpen?: (isModalLockedOpen: boolean) => void
}

export const IntegrationContext = createContext<IntegrationContextType>({
    visibility: false,
    isModalLockedOpen: false,
    setIsModalLockedOpen: () => {},

})

export const IntegrationProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalLockedOpen, setIsModalLockedOpen] = useState(false)

    return (
        <IntegrationContext.Provider
            value={{ 
                isModalLockedOpen: isModalLockedOpen,
                setIsModalLockedOpen: setIsModalLockedOpen,
            }}
        >
            {children}
        </IntegrationContext.Provider>
    )
}
