import { npcsQuery } from 'entities/db'
import { version } from 'os'
import { start } from 'repl'
import { z } from 'zod'

export const CreateDialogLineResponseSchema = z.object({
    response: z.string(),
    version: z.number(),
    send_trigger: z.string().optional().nullable(),
    instruction: z.string().optional().nullable(),
})

export const CreateDialogLineDtoSchema = z.object({
    npc_name: z.string(),
    llama_generation_params: z.object({
        max_tokens: z.number(),
        temperature: z.number(),
        stream: z.boolean(),
        stop: z.array(z.string()),
        top_p: z.number(),
        top_k: z.number(),
        frequency_penalty: z.number(),
        presence_penalty: z.number(),
    }),
    toxicity: z.number(),
    core_description: z.string(),
    question: z.string(),
    language: z.string(),
    history: z.array(z.string()).optional(),
})

export const DialogLineSchema = z.object({
    npc_name: z.string(),
    llama_generation_params: z.object({
        max_tokens: z.number(),
        temperature: z.number(),
        stream: z.boolean(),
        stop: z.array(z.string()),
        top_p: z.number(),
        top_k: z.number(),
        frequency_penalty: z.number(),
        presence_penalty: z.number(),
    }),
    toxicity: z.number(),
    core_description: z.string(),
    question: z.string(),
    language: z.string(),
    response: z.string(),
})

export const DialogLinesSchema = z.array(DialogLineSchema)

export const InitSessionResponseSchema = z.object({
    session_id: z.string(),
    message: z.string(),
})

export const CreateUploadDiagenFilesSchema = z.object({
    topics: z.instanceof(File),
    diagenEvents: z.instanceof(File),
    characterInformation: z.instanceof(File),
})
export const UploadDiagenFilesResponseSchema = z.object({
    message: z.string(),
})

export const UpdateHistoryResponseSchema = z.object({
    message: z.string(),
})

export const CreateCallStateChangesSchema = z.object({
    npc_name: z.string(),
    stateTags: z.array(z.string()),
})

export const CallStateChangesResponseSchema = z.object({
    changes: z.string(),
})

export const CreateDiagenEventSchema = z.object({
    npc_name: z.string(),
    diagenEvent: z.string(),
})

export const UpdateHistorySchema = z.object({
    npc_name: z.string(),
    question: z.string(),
    response: z.string(),
})
export const DiagenEventResponseSchema = z.object({
    ReturnTrigger: z.string(),
    SayVerbatim: z.string(),
    Instruction: z.string(),
    StateTags: z.array(z.string()),
})

export const getTagsResponseSchema = z.object({
    stateTags: z.array(z.string()),
})
export const CreateTopicDetectionSchema = z.object({
    question: z.string(),
    npc_name: z.string(),
    model_path: z.string(),
    llama_generation_params: z.object({
        max_tokens: z.number(),
        temperature: z.number(),
        top_p: z.number(),
    }),
    llama_load_params: z.object({
        n_gpu_layers: z.number(),
        n_ctx: z.number(),
        main_gpu: z.number(),
    }),
})

export const CreatePromptSchema = z.object({
    npc_name: z.string(),
    player_name: z.string(),
    local_exec: z.boolean(),
})

export const CreatePromptResponseSchema = z.object({
    prompt: z.string(),
})



// export const GetStateSchema = z.object({
//     npc_name: z.string(),
// })

// export const GetStateResponseSchema = z.object({
//     session_id: z.string(),
//     start_time: z.string(),
//     update_time: z.string(),
//     executed_triggers: z.array(z.string()),
//     suffix: z.array(z.string()),
//     history: z.array(z.string()),
//     topics: z.array(z.string()),
//     stateTags: z.array(z.string()),
// })