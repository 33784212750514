import { sessionModel } from 'entities/session'
import { dbBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation, createJsonQuery } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CharactersSchema, MessageResponseDtoSchema } from './character.contracts'

export async function audioQuery() {
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/audio?cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(CharactersSchema),
        },
    })  
}


export async function imageUploadMutation(params: {
    file: File | null
    team: string | null
    npc: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.file) {
        body.append('file', params.file);
    }
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.npc) {
        body.append('npc', params.npc);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/image`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function deleteNpcMutation(params: {
    team: string | null
    npc: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.team) {
        body.append('team', params.team);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/npc/${params.npc}`),
            method: 'DELETE',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function audioUploadMutation(params: {
    file: File | null
    team: string | null
    npc: string | null
    description: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.file) {
        body.append('file', params.file);
    }
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.npc) {
        body.append('npc', params.npc);
    }
    if (params.description) {
        body.append('description', params.description);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/audio`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function deleteAudioMutation(params: {
    team: string | null
    npc: string | null
    description: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.npc) {
        body.append('npc', params.npc);
    }
    if (params.description) {
        body.append('description', params.description);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/audio`),
            method: 'DELETE',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function createNpcMutation(params: {
    team: string | null
    npc: string | null
    description: string | null
    // language: string[] | null
    emotion: string[] | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.team) {
        body.append('team', params.team);
        body.append('model', `${params.team}_model`);
    }
    if (params.npc) {
        body.append('name', params.npc);
    }
    if (params.description) {
        body.append('background', params.description);
    }
    // if (params.language) {
    //     body.append('language', params.language.join(','));
    // }
    if (params.emotion) {
        body.append('emotion', params.emotion.join(','));
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/npc/create`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function triggerUploadMutation(params: {
    file: File | null
    team: string | null
    npc: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.file) {
        body.append('file', params.file);
    }
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.npc) {
        body.append('npc', params.npc);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/trigger/dataset`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(CharactersSchema),
        },
    })
}

export async function textUploadMutation(params: {
    file: File | null
    team: string | null
    npc: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.file) {
        body.append('file', params.file);
    }
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.npc) {
        body.append('npc', params.npc);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/dataset`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(CharactersSchema),
        },
    })
}

export async function copyNpcMutation(params: {
    team: string | null
    target_team: string | null
    npc: string | null
}) {

    let body: FormData | string | undefined = undefined;
    body = new FormData();
    if (params.team) {
        body.append('team', params.team);
    }
    if (params.target_team) {
        body.append('target_team', params.target_team);
    }

    return createFormMutation({
        request: {
            url: dbBaseUrl(`/npc/copy/${params.npc}`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
            body: body,
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}