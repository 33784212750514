import { AdminModel } from 'entities/admin'
import { createContext, useState, useEffect } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'

const initialAdminState: AdminModel.State = {
    userStates: [
        {
            id: undefined,
            name: '',
            email: '',
            is_owner: false,
            is_admin: false,
            can_manage_models: false,
            can_manage_npcs: false,
            can_manage_datasets: false,
            can_download_gepetto: false,
            can_download_ariel: false,
            can_download_diagen: false,
            date_joined: '',
            valid_from: '',
            valid_until: '',
        },
    ],
}

const adminStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'AdminPage AdminStore',
}

const playerStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'AdminPage AdminStore',
}

export const adminStore = createStore<AdminModel.AdminState>()(
    devtools(
        AdminModel.createAdminSlice(initialAdminState),
        adminStoreDevtoolsOptions
    )
)

type AdminContextType = {
    adminStore: StoreApi<AdminModel.AdminState>
    setIsConfirmationDeleteOpen?: (value: boolean) => void
    isConfirmationDeleteOpen: boolean
    setIsConfirmationCreateOpen?: (value: boolean) => void
    isConfirmationWarningOpen: boolean
    setIsConfirmAccessRightsOpen?: (value: boolean) => void
    isConfirmAccessRightsOpen: boolean
    setIsConfirmationAddOpen?: (value: boolean) => void
    isConfirmationAddOpen: boolean
    setIsConfirmationWarningOpen?: (value: boolean) => void
    isConfirmationModifyOpen: boolean
    setIsConfirmationModifyOpen?: (value: boolean) => void
    isConfirmationCreateOpen: boolean
    setCreateTeamName?: (value: string) => void
    createValidUntil: string
    createValidFrom: string
    setUpdateValidFrom?: (value: string) => void
    updateValidFrom: string
    setUpdateValidUntil?: (value: string) => void
    updateValidUntil: string
    setCreateValidFrom?: (value: string) => void
    setCreateValidUntil?: (value: string) => void
    createTeamName: string
    setUserAccessRights?: (value: AdminModel.User) => void
    userAccessRights: AdminModel.User
    setRequestUpdate?: (value: boolean) => void
    requestUpdate: boolean
    setUpdateUserName?: (value: string) => void
    updateUserName: string
    setTargetTeamsList?: (value: string[]) => void
    targetTeamsList: string[]
}

export const AdminContext = createContext<AdminContextType>({
    adminStore,
    isConfirmationDeleteOpen: false,
    isConfirmationModifyOpen: false,
    isConfirmationCreateOpen: false,
    isConfirmationWarningOpen: false,
    isConfirmAccessRightsOpen: false,
    isConfirmationAddOpen: false,
    createTeamName: '',
    createValidUntil: '',
    createValidFrom: '',
    userAccessRights: {
        id: undefined,
        name: '',
        email: '',
        is_owner: false,
        is_admin: false,
        can_manage_models: false,
        can_manage_npcs: false,
        can_manage_datasets: false,
        can_download_gepetto: false,
        can_download_ariel: false,
        can_download_diagen: false,
        date_joined: '',
        valid_from: '',
        valid_until: '',
    },
    requestUpdate: false,
    updateUserName: '',
    updateValidFrom: '',
    updateValidUntil: '',
    targetTeamsList: [],
})

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
    const [isConfirmationDeleteOpen, setIsConfirmationDeleteOpen] = useState(false)
    const [isConfirmationCreateOpen, setIsConfirmationCreateOpen] = useState(false)
    const [isConfirmationWarningOpen, setIsConfirmationWarningOpen] = useState(false)
    const [isConfirmAccessRightsOpen, setIsConfirmAccessRightsOpen] = useState(false)
    const [isConfirmationModifyOpen, setIsConfirmationModifyOpen] = useState(false)
    const [isConfirmationAddOpen, setIsConfirmationAddOpen] = useState(false)
    const [createValidFrom, setCreateValidFrom] = useState('')
    const [createValidUntil, setCreateValidUntil] = useState('')
    const [createTeamName, setCreateTeamName] = useState('')
    const [userAccessRights, setUserAccessRights] = useState<AdminModel.User>({
        id: undefined,
        name: '',
        email: '',
        is_owner: false,
        is_admin: false,
        can_manage_models: false,
        can_manage_npcs: false,
        can_manage_datasets: false,
        can_download_gepetto: false,
        can_download_ariel: false,
        can_download_diagen: false,
        date_joined: '',
        valid_from: '',
        valid_until: '',
    })
    const [requestUpdate, setRequestUpdate] = useState(false)
    const [updateUserName, setUpdateUserName] = useState('')
    const [updateValidFrom, setUpdateValidFrom] = useState('')
    const [updateValidUntil, setUpdateValidUntil] = useState('')
    const [targetTeamsList, setTargetTeamsList] = useState<string[]>([])


    return (
        <>
        <AdminContext.Provider
            value={{
                adminStore,
                isConfirmationDeleteOpen: isConfirmationDeleteOpen,
                setIsConfirmationDeleteOpen: setIsConfirmationDeleteOpen,
                isConfirmationCreateOpen: isConfirmationCreateOpen,
                setIsConfirmationWarningOpen: setIsConfirmationWarningOpen,
                isConfirmationWarningOpen: isConfirmationWarningOpen,
                setIsConfirmationCreateOpen: setIsConfirmationCreateOpen,
                isConfirmationModifyOpen: isConfirmationModifyOpen,
                setIsConfirmationModifyOpen: setIsConfirmationModifyOpen,
                isConfirmAccessRightsOpen: isConfirmAccessRightsOpen,
                setIsConfirmAccessRightsOpen: setIsConfirmAccessRightsOpen,
                isConfirmationAddOpen: isConfirmationAddOpen,
                setIsConfirmationAddOpen: setIsConfirmationAddOpen,
                updateValidFrom: updateValidFrom,
                setUpdateValidFrom: setUpdateValidFrom,
                updateValidUntil: updateValidUntil,
                setUpdateValidUntil: setUpdateValidUntil,
                createValidFrom: createValidFrom,
                setCreateValidFrom: setCreateValidFrom,
                createValidUntil: createValidUntil,
                setCreateValidUntil: setCreateValidUntil,
                createTeamName: createTeamName,
                setCreateTeamName: setCreateTeamName,
                userAccessRights: userAccessRights,
                setUserAccessRights: setUserAccessRights,
                requestUpdate: requestUpdate,
                setRequestUpdate: setRequestUpdate,
                updateUserName: updateUserName,
                setUpdateUserName: setUpdateUserName,
                targetTeamsList: targetTeamsList,
                setTargetTeamsList: setTargetTeamsList,
            }}
        >
            {children}
        </AdminContext.Provider>
        </>
    )
}
