import { z } from 'zod'

export const CharacterSchema = z.object({
    team: z.string(),
    model: z.string(),
    language: z.string(),
    background: z.string(),
    created_at: z.string(),
})

export const CharactersSchema = z.array(CharacterSchema)

export const MessageResponseDtoSchema = z.object({
    message: z.string(),
    id: z.string().optional(),
})
