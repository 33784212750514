import {
    PopupLogsBestPractice,
} from 'devlink'
import { LogsContext } from 'pages/logsanalysis/logsanalysis-page.model';
import React, { useContext, useEffect, useCallback } from 'react';


export function PopupLogsBestPracticeContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTriggered,
    } = useContext(LogsContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false);
    }, [setIsModalBestPracticeOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupLogsBestPractice
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalBestPracticeOpen?.(false);
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTriggered?.(true)
                },
            }}
        />
    )
}