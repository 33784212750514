import { faCheck, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function PageHeaderAdmin({
    as: _Component = _Builtin.Block,
    pageHeaderTitle = 'Administration',
    slotPageHeaderLargeButton,
    slotGenerationProcessing,
    buttonLink = {
        href: '#',
    },
    rpTeamsDropdown = {},
    rpCopyKeys = {},
    showCopyPopup,
    showDropdown,

}) {


    return (
        <_Component className="header" tag="div">
            <_Builtin.Block
                className="background-color-blue200 padding-small"
                tag="div"
            >
                <_Builtin.Block className="header-container" tag="div">
                    <_Builtin.Block className="header-left" tag="div">
                        <_Builtin.Heading tag="h1">
                            {pageHeaderTitle}
                        </_Builtin.Heading>
                    </_Builtin.Block>
                    <_Builtin.Block className="header-right" tag="div">
                        <_Builtin.Block
                            className="button is-icon-below right"
                            tag="div"
                            {...rpCopyKeys}
                        >
                            {showCopyPopup ? (
                                <>
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={'Copied!'} />
                                </>
                            ) : (
                                <>
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={faKey}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={'Keys'} />
                                </>
                            )}
                        </_Builtin.Block> 
                        {slotPageHeaderLargeButton ?? (
                            <>
                                {showDropdown && (
                                    <_Builtin.Block
                                        className="slotpageheadersmallbutton"
                                        tag="div"
                                    >
                                        <_Builtin.FormSelect
                                            className="input background-blue-150 min-h-3-4rem"
                                            name="Teams"
                                            data-name="Teams"
                                            required={false}
                                            multiple={false}
                                            id="Teams-1"
                                            {...rpTeamsDropdown}
                                        />
                                            <_Builtin.Block tag="div">
                                            {slotGenerationProcessing ?? (
                                                <LoaderGenerating />
                                            )}
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                )}
                            </>
                        )}
                
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
