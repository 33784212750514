import { HeaderLayout } from 'devlink'
import { sessionStore } from 'entities/session/session.model'
import { useEffect, useState } from 'react'
import { PageHeaderDataUsage, SectionDataUsage } from 'widgets/datausage'
import { DataUsageProvider } from './datausage-page.model'

export function DataUsagePage() {
    const [selectedKeys, setSelectedKeys] = useState<any>(sessionStore.getState().keys || {})

    useEffect(() => {   
        // Session store
        const setComponentStateFromSessionStore = (state: any) => {
            setSelectedKeys(state.keys || {});
        };

        // Manually set the initial state from the store
        const initialSessionState = sessionStore.getState();
        setComponentStateFromSessionStore(initialSessionState);
    
        // Subscribe to future store changes
        const unsubscribeSessionStore = sessionStore.subscribe((state) => {
            if (state.keys) {
                setComponentStateFromSessionStore(state);
            }
        });
    

        // Cleanup function to unsubscribe
        return () => {
            unsubscribeSessionStore();
        };
    }, [])

    return (
        <>
            <DataUsageProvider>
                <HeaderLayout
                    slotHeader={
                        <PageHeaderDataUsage/>
                    }
                    slotHeaderLayout={<SectionDataUsage selectedKeys={selectedKeys}/>}
                />
            </DataUsageProvider>
        </>
    )
}
