import { z } from 'zod'

export const UserDtoSchema = z.object({
    user: z.object({
        email: z.string(),
        token: z.string(),
        username: z.string(),
        bio: z.nullable(z.string()),
        image: z.string(),
    }),
})

export const UpdateUserDtoSchema = z
    .object({
        email: z.string().email().optional().or(z.literal('')),
        username: z.string().min(5).optional().or(z.literal('')),
        bio: z.string().optional().or(z.literal('')),
        image: z.string().optional().or(z.literal('')),
        password: z.string().min(8).optional().or(z.literal('')),
    })
    .partial()
    .refine((args) => Object.values(args).some(Boolean), {
        path: ['form'],
        message: 'One of the fields must be defined',
    })

export const CreateUserDtoSchema = z.object({
    username: z.string().min(5),
    email: z.string().email(),
    password: z.string().min(8),
})

export const LoginUserResponseDtoSchema = z.object({
    AccessToken: z.string(),
    ExpiresIn: z.number(),
    TokenType: z.string(),
    RefreshToken: z.string(),
    IdToken: z.string(),
    challenge: z.boolean(),
})

export const TeamDtoSchema = z.object({
    name: z.string(),
    is_owner: z.number(),
    is_admin: z.number(),
    can_manage_models: z.number(),
    can_manage_npcs: z.number(),
    can_manage_datasets: z.number(),
    valid_until: z.string(),
    can_download_gepetto: z.number(),
    can_download_ariel: z.number(),
    can_download_diagen: z.number(),
})

export const TeamsResponseDtoSchema = z.array(TeamDtoSchema)


export const KeyUsageSchema = z.object({
    date: z.string(),
    usage: z.number(),
  });

export const DownloadUsageSchema = z.object({
    date: z.string(),
    usage: z.number(),
  });

  export const KeySchema = z.object({
    key: z.string(),
    max_requests: z.number(),
    batch: z.number(),
    valid_until: z.string(),
    valid_from: z.string(),
    usage: z.array(KeyUsageSchema),
    download: z.array(DownloadUsageSchema),
  });

export const KeysResponseDtoSchema = z.object({
    diagen: KeySchema,
    gepetto: KeySchema,
    ariel: KeySchema,
})

export const LoginUserDtoSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8),
})

const UserAccessRightsSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    email: z.string().email(),
    is_owner: z.number(),
    is_admin: z.number(),
    can_manage_models: z.number(),
    can_manage_npcs: z.number(),
    can_manage_datasets: z.number(),
    can_download_gepetto: z.number(),
    can_download_ariel: z.number(),
    can_download_diagen: z.number(),
    date_joined: z.string(),
    valid_from: z.string(),
    valid_until: z.string(),
});

export const UsersResponseDtoSchema = z.record(z.string().uuid(), UserAccessRightsSchema);

export const UserSchema = z.object({
    email: z.string(),
    token: z.string(),
    username: z.string(),
    bio: z.string(),
    image: z.string(),
})

export const MessageResponseDtoSchema = z.object({
    message: z.string(),
})

export const SignupResponseDtoSchema = z.object({
    uid: z.string(),
})